/* eslint-disable */
import { ENDPOINT_DASHBOARD_RFX, ENDPOINT_RFP_LOG, ENDPOINT_RFQ_LOG, ENDPOINT_DASHBOARD_RFX_CHECK } from '@/core/constant/menuEndpoint'

export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'projectLog',

	state: {
    activeButton: 'Outstanding',
    projectLogStatus: {
      empty: false,
      first: true,
      last: false,
      number: 0,
      numberOfElements: 20,
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true,
        sort: {
          empty: false,
          sorted: true,
          unsorted: false,
        },
        unpaged: false
      },
      size: 10,
      sort: {
        empty: false,
        sorted: true,
        unsorted: false,
      },
      totalElements: 236,
      totalPages: 2,
      content: []
    },
    projectLogStatusDraft: {},
    projectLogStatusCompleted: {},
    projectLogRfp: {
      rejectReason: null,
      rfpLogs: []
    },
    projectLogRfq: {
      rejectReason: null,
      rfqLogs: []
    },
    projectId: null,
    rfpLogId: null,
    rfqLogId: null,
    counterInterval: null,
    canAccess: true,
	},

	getters: {},

	mutations: {
    SET_ACTIVE_BUTTON(state, payload) {
      state.activeButton = payload
    },
    SET_PROJECT_LOG_STATUS(state, payload) {
      state.projectLogStatus = payload
    },
    SET_PROJECT_LOG_STATUS_DRAFT(state, payload) {
      state.projectLogStatusDraft = payload
    },
    SET_PROJECT_LOG_STATUS_COMPLETED(state, payload) {
      state.projectLogStatusCompleted = payload
    },
    SET_PROJECT_LOG_RFP(state, payload) {
      state.projectLogRfp = payload
    },
    SET_PROJECT_LOG_RFQ(state, payload) {
      state.projectLogRfq = payload
    },
    SET_PROJECT_ID(state, payload) {
      state.projectId = payload
    },
    SET_RFP_LOG_ID(state, payload) {
      state.rfpLogId = payload
    },
    SET_RFQ_LOG_ID(state, payload) {
      state.rfqLogId = payload
    },
    SET_COUNTER_INTERVAL(state, payload) {
      state.counterInterval = payload
    },
    SET_CAN_ACCESS(state, payload) {
      state.canAccess = payload
    }
	},
	actions: {
    async getProjectLogStatus({ commit }, payload) {
      const { projectRequestType, projectStatus, status, dateFrom, dateTo, page, sort, anySearch, size } = payload
      try {
        const response = await this.$api.get(ENDPOINT_DASHBOARD_RFX, { projectRequestType, projectStatus, status, dateFrom, dateTo, page, sort, anySearch, size })
        const result = response.data.result
        console.log(result, 'result project list');
        if (projectStatus === 'draft') {
          commit('SET_PROJECT_LOG_STATUS_DRAFT', result)
        } else if (projectStatus === 'completed') {
          commit('SET_PROJECT_LOG_STATUS_COMPLETED', result)
        } else {
          commit('SET_PROJECT_LOG_STATUS', result)
        }
        return response
      } catch (error) {
        console.log(error);
        if (projectStatus === 'draft') {
          commit('SET_PROJECT_LOG_STATUS_DRAFT', {})
        } else if (projectStatus === 'completed') {
          commit('SET_PROJECT_LOG_STATUS_COMPLETED', {})
        } else {
          commit('SET_PROJECT_LOG_STATUS', {})
        }
        return error.response
      }
    },
    async refetchProjectLogStatus({ commit, state, dispatch, rootState }, payload) {
      const filterProject = rootState.dashboard.filterProject
      await dispatch('getProjectLogStatus', { projectStatus: 'outstanding', projectRequestType: filterProject.type, status: filterProject.status, dateFrom: filterProject.start, dateTo: filterProject.end, anySearch: filterProject.search, page: filterProject.currentPage, sort: filterProject.sort, size: filterProject.pageCount })
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
      commit('SET_COUNTER_INTERVAL', setInterval( async() => {
        await dispatch('getProjectLogStatus', { projectStatus: 'outstanding', projectRequestType: filterProject.type, status: filterProject.status, dateFrom: filterProject.start, dateTo: filterProject.end, anySearch: filterProject.search, page: filterProject.currentPage, sort: filterProject.sort, size: filterProject.pageCount })
      }, 3000))
			setTimeout(() => {
				clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
			}, 30000)
    },
    async getCheckAccess({ commit, state }, payload) {
      try {
        const { projectRequestId, projectRequestType } = payload
        const response = await this.$api.get(ENDPOINT_DASHBOARD_RFX_CHECK, { projectRequestId, projectRequestType })
        if (response?.data?.code === 200) {
          commit('SET_CAN_ACCESS', true)
        } else {
          commit('SET_CAN_ACCESS', false)
        }
        return response
      } catch (error) {
        console.log(error);
        const { home } = payload
        if(error.response.data.code === 400 && !home) commit('SET_CAN_ACCESS', false)
        return error.response
      }
    },
    async getProjectLogByRfpId({commit, state}, payload) {
      try {
        const { rfpId, toggleExpanded } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_LOG}/${rfpId}`)
        console.log(response, 'response get project log by rfp id');
        const result = response.data.result
        if (toggleExpanded) {
          const dataCommit = { ...result, rfpLogs: result.rfpLogs?.map((e,i) => ({ ...e, toggleExpand: state.projectLogRfp?.rfpLogs?.[i]?.toggleExpand || e.toggleExpand }))}
          commit('SET_PROJECT_LOG_RFP', dataCommit)
        } else {
          commit('SET_PROJECT_LOG_RFP', result)
        }
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getProjectLogByRfqId({commit, state}, payload) {
      try {
        const { rfqId, toggleExpanded } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_LOG}/${rfqId}`)
        console.log(response, 'response get project log by rfq id');
        const result = response.data.result
        if (toggleExpanded) {
          const dataCommit = { ...result, rfqLogs: result.rfqLogs?.map((e,i) => ({ ...e, toggleExpand: state.projectLogRfq?.rfqLogs?.[i]?.toggleExpand || e.toggleExpand }))}
          commit('SET_PROJECT_LOG_RFQ', dataCommit)
        } else {
          commit('SET_PROJECT_LOG_RFQ', result)
        }
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async refetchProjectLogByRfpId({ commit, state, dispatch, rootState }, payload) {
      const rfpId = payload
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
			await dispatch('getProjectLogByRfpId', { rfpId, toggleExpanded: true })
			commit('SET_COUNTER_INTERVAL', setInterval( async() => {
        console.log('called interval');
				await dispatch('getProjectLogByRfpId', { rfpId, toggleExpanded: true })
				await dispatch('projectDetail/getLogApproval', { rfpId }, { root: true })
			}, 3000))
			setTimeout(() => {
				clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
			}, 30000)
    },
    async refetchProjectLogByRfqId({ commit, state, dispatch, rootState }, payload) {
      const rfqId = payload
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
			await dispatch('getProjectLogByRfqId', { rfqId, toggleExpanded: true })
      commit('SET_COUNTER_INTERVAL', setInterval( async() => {
				await dispatch('getProjectLogByRfqId', { rfqId, toggleExpanded: true })
      }, 3000))
			setTimeout(() => {
				clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
			}, 30000)
    },
    async refetchApprovalProcurementLog({ commit, state, dispatch, rootState }, payload) {
      const { rfpId } = payload
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
      await dispatch('procurementApproval/getApproval', { rfpId }, { root: true })
			commit('SET_COUNTER_INTERVAL', setInterval(async() => {
        await dispatch('procurementApproval/getApprovalLog', { approvalId: rootState.procurementApproval.dataProcurementApproval.id }, { root: true })
				await dispatch('getProjectLogByRfpId', { rfpId, toggleExpanded: true })
			}, 3000))
			setTimeout(() => {
        dispatch('procurementApproval/getApproval', { rfpId }, { root: true })
				clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
			}, 30000)
    },
    async refetchApprovalProcurementRfqLog({ commit, state, dispatch, rootState }, payload) {
      const { rfqId } = payload
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
      await dispatch('procurementApprovalRfq/getApproval', { rfqId }, { root: true })
			commit('SET_COUNTER_INTERVAL', setInterval(async() => {
        await dispatch('procurementApprovalRfq/getApprovalLog', { approvalId: rootState.procurementApprovalRfq.dataProcurementApproval.id }, { root: true })
				await dispatch('getProjectLogByRfqId', { rfqId, toggleExpanded: true })
			}, 3000))
			setTimeout(() => {
        dispatch('procurementApprovalRfq/getApproval', { rfqId }, { root: true })
				clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
			}, 30000)
    },
    async refetchSktLoiLog({ commit, state, dispatch, rootState }, payload) {
      const { rfpId } = payload
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
			commit('SET_COUNTER_INTERVAL', setInterval(async() => {
        await dispatch('sktLoi/getSkt', { rfpId }, { root: true })
				await dispatch('getProjectLogByRfpId', { rfpId, toggleExpanded: true })
			}, 3000))
			setTimeout(() => {
				clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
			}, 30000)
    },
    async refetchLoiRfqLog({ commit, state, dispatch, rootState }, payload) {
      const { rfqId } = payload
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
			commit('SET_COUNTER_INTERVAL', setInterval(async() => {
        await dispatch('loiRfq/getLoi', { rfqId }, { root: true })
				await dispatch('getProjectLogByRfqId', { rfqId, toggleExpanded: true })
			}, 3000))
			setTimeout(() => {
				clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
			}, 30000)
    },
    async refetchSppRfpLog({ commit, state, dispatch, rootState }, payload) {
      const { rfpId } = payload
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
			commit('SET_COUNTER_INTERVAL', setInterval(async() => {
        await dispatch('spp/getSpp', { rfpId }, { root: true })
				await dispatch('getProjectLogByRfpId', { rfpId, toggleExpanded: true })
			}, 3000))
			setTimeout(() => {
				clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
			}, 30000)
    },
    async refetchSppRfqLog({ commit, state, dispatch, rootState }, payload) {
      const { rfqId } = payload
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
			commit('SET_COUNTER_INTERVAL', setInterval(async() => {
        await dispatch('sppRfq/getSpp', { rfqId }, { root: true })
				await dispatch('getProjectLogByRfqId', { rfqId, toggleExpanded: true })
			}, 3000))
			setTimeout(() => {
				clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
			}, 30000)
    },
	}
}