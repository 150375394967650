<template>
	<div class="w-full" v-if="approvalPage && isSubmitted && logApproval.isEnableApprovalButton && userApprover && menuRfpApprovalApprove?.canUpdate && !counterInterval">
		<div class="w-full p-4">
      <p class="text-sm font-normal text-gray">Approval for Procurement</p>
      <div class="flex flex-wrap mt-1 mb-6 text-sm font-medium text-gray-dark">Please review the Procurement</div>
      <div class="flex flex-col justify-between gap-6 sm:flex-row">
        <ButtonGista 
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggle"
          customClass="w-full">
          Reject
        </ButtonGista>
        <ButtonGista 
          type="primary"
          color="success"
          @click="toggleApprove"
          class="w-full">
          Approve</ButtonGista>
      </div>
		</div>
		<GistaModal 
      :value="isOpenReject"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak Persetujuan</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="notes"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-60">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
		<GistaModal 
      :value="isOpenApprove"
      customClass="max-w-2xl bg-white"
			@input="toggleApprove">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="w-full mb-1">
            <h4 class="mb-8 text-3xl text-center font-medium">Setujui</h4>
            <div class="flex-wrap w-full mb-4">
              <ValidationProvider name="Reason" rules="required|max:500" v-slot="{errors}">
                <TextareaGista
                  label="Reason"
                  placeholder="Leave a comment here"
                  v-model="notes"
                  :error="!!errors[0]"
                  mandatory
                >
                  <template #message>
                    <p>{{errors[0]}}</p>
                  </template>
                </TextareaGista>
              </ValidationProvider>

            </div>
          </div>
          <div class="flex justify-center">
            <ButtonGista 
              color="success"
              customClass="w-full sm:w-56"
              @click="handleSubmit(clickApprove)"
              >
              Approve
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_APPROVAL_APPROVE, MESSAGE_SUCCESS_APPROVAL_REJECT } from '@/core/constant/successMessage'
export default {
	name: "ProcurementApproveReject",
	data() {
		return {
			isOpenReject: false,
			isOpenApprove: false,
      notes: '',
		}
	},
  computed: {
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
    menuRfpApprovalApprove() {
			return this.$store.getters['dashboard/menuRfpApprovalApprove']
		},
    dataProcurementApproval() {
      return this.$store.state.procurementApproval.dataProcurementApproval
    },
    isSubmitted() {
      return this.dataProcurementApproval.isSubmitted
    },
    logApproval() {
      return this.$store.state.procurementApproval.logApproval
    },
    approvalPage() {
      return this.$route.path.includes('approval')
    },
    userProfile() {
			return this.$store.state.dashboard.userProfile
		},
    userApprover() {
      return this.logApproval.waitingApproval.split(',').includes(this.userProfile.username)
    },
    counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
  },
	methods: {
    async clickReject() {
      this.modal.modalLoading = true
      const response = await this.$store.dispatch('procurementApproval/putApprovalApproval', { approvalId: this.dataProcurementApproval.id, notes: this.notes, action: 'REJECT' })
      if (response?.status < 300) {
        this.toggle()
        this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_APPROVAL_REJECT }
        this.$store.dispatch('projectLog/refetchApprovalProcurementLog', { rfpId: this.$route.params.projectId })

      }
      this.modal.modalLoading = false
    },
    async clickApprove() {
      this.modal.modalLoading = true
      const response = await this.$store.dispatch('procurementApproval/putApprovalApproval', { approvalId: this.dataProcurementApproval.id, notes: this.notes, action: 'APPROVE' })
      if (response?.status < 300) {
        this.toggleApprove()
        this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_APPROVAL_APPROVE }
        this.$store.dispatch('projectLog/refetchApprovalProcurementLog', { rfpId: this.$route.params.projectId })

      }
      this.modal.modalLoading = false
    },
		toggle() {
			this.isOpenReject = !this.isOpenReject
      this.notes = ''
		},
		toggleApprove() {
			this.isOpenApprove = !this.isOpenApprove
      this.notes = ''
		},
	},
}
</script>

<style scoped>
.width-button-submit {
  max-width: 40rem;
}
</style>