/* eslint-disable */
import { ENDPOINT_RFP_SPP, ENDPOINT_RFP_SPP_SUBMIT, ENDPOINT_RFP_SPP_APPROVAL, ENDPOINT_RFP_SPP_CHANGE_APPROVAL, ENDPOINT_RFP_SPP_CANCEL, ENDPOINT_RFP_SPP_PREVIEW, ENDPOINT_RFP_SPP_SECURITY_CODE } from '@/core/constant/menuEndpoint'

export const Store = {
  namespaced: true,
  name: 'spp',
  state: {
    showSpp: false,
    mainPage: '',
    dataSpp: {approvals: []},
    approvals: {
      REVIEWER_DLOG: { search: '' },
      APPROVER_DLOG: { search: '' },
      APPROVER_GSIT: { search: '' },
      REVIEWER_GSIT: { search: '' },
    },
    sppProcess: false
  },
  mutations: {
    SET_SHOW_SPP(state, payload) {
      state.showSpp = payload
    },
    SET_MAIN_PAGE(state, payload) {
      state.mainPage = payload
    },
    SET_DATA_SPP(state, payload) {
      state.dataSpp = payload
    },
    SET_APPROVALS(state, payload) {
      state.approvals = payload
    },
    SET_SPP_PROCESS(state, payload) {
      state.sppProcess = payload
    },
  },
  actions: {
    resetState({ commit, state, dispatch }, payload) {
      const {resetAll} = payload
      commit('SET_APPROVALS', {
        REVIEWER_DLOG: { search: '' },
        APPROVER_DLOG: { search: '' },
        APPROVER_GSIT: { search: '' },
        REVIEWER_GSIT: { search: '' },
      })
      if(resetAll) {
        commit('SET_SHOW_SPP', false)
        commit('SET_SPP_PROCESS', false)
      }
    },
    dataPayloadSpp({ commit, state, dispatch }, payload) {
      const arrayApprovals = []
      for (const key in state.approvals) {
        if (state.approvals[key].username) {
          const { search, ...res } = state.approvals[key]
          arrayApprovals.push(res)
        }
      }
      const { isNew, lastModifiedAt, error, statusDocument, ...data } = { 
        ...state.dataSpp,
        approvals: arrayApprovals
      }
      return data
    },
    async dataPayloadPreviewSpp({ commit, state, dispatch }, payload) {
      const dataPayload = await dispatch('dataPayloadSpp')
      const {indexSpp} = payload
      const sppVendor = dataPayload.sppVendors[indexSpp]
      const { sppVendors, ...data } = { 
        ...dataPayload,
        sppVendor
      }
      return data
    },
    setCommit({ commit, state, dispatch }, payload) {
      const { result } = payload
      for (const iterator of result.approvals) {
        commit('SET_APPROVALS', { ...state.approvals, [iterator.approvalType]: { ...iterator, search: iterator.name} })
      }
    },
    async getSpp({ commit, state, dispatch }, payload) {
      try {
        const {rfpId} = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_SPP}/${rfpId}`)
        const dataCommit = { ...response.data.result, 
          error: {
            'Cancellation Request': false,
            'Approver DLOG': '',
            'Approver GSIT': ''
          },
          sppVendors: response.data.result.sppVendors?.map((e,i) => ({
            ...e,
            securityCode: state.dataSpp?.sppVendors?.[i]?.securityCode ?? '',
            openSecurityCode: state.dataSpp?.sppVendors?.[i]?.openSecurityCode !== undefined? state.dataSpp?.sppVendors?.[i]?.openSecurityCode : false,
            error: {
              'Email Vendor Principal': '',
              'PIC Vendor Principal': '',
              'Email Local Partner': '',
              'PIC Local Partner': ''
            }, 
            collapse: state.dataSpp?.sppVendors?.[i]?.collapse !== undefined? state.dataSpp?.sppVendors?.[i]?.collapse : false
          }))
        }
        // to check whether the spp is in progress or not
        commit('SET_SPP_PROCESS', dataCommit.statusDocument !== 'SPP_DRAFT' && dataCommit.statusDocument !== null)
        commit('SET_DATA_SPP', dataCommit)
        dispatch('setCommit', { result: response.data.result })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async saveAsDraftSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadSpp')
        return await this.$api.post(ENDPOINT_RFP_SPP, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async updateDraftSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadSpp')
        return await this.$api.put(ENDPOINT_RFP_SPP, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async submitSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadSpp')
        return await this.$api.post(ENDPOINT_RFP_SPP_SUBMIT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async updateSubmitSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadSpp')
        return await this.$api.put(ENDPOINT_RFP_SPP_SUBMIT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async approvalSpp({ commit, state, dispatch }, payload) {
      try {
        const { rfpId, data } = payload
        return await this.$api.put(`${ENDPOINT_RFP_SPP_APPROVAL}/${rfpId}`, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async changeApprovalSpp({ commit, state, dispatch }, payload) {
      try {
        const { rfpId, data } = payload
        return await this.$api.put(`${ENDPOINT_RFP_SPP_CHANGE_APPROVAL}/${rfpId}`, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async cancelApprovalSpp({ commit, state, dispatch }, payload) {
      try {
        const {rfpId} = payload
        return await this.$api.put(`${ENDPOINT_RFP_SPP_CANCEL}/${rfpId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async previewSpp({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataPayloadPreviewSpp', payload)
        const response = await this.$api.post(ENDPOINT_RFP_SPP_PREVIEW, data, { responseType: 'blob' })
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getSppSecurityCode({ commit, state, dispatch }, payload) {
      try {
        const { vendorCandidateId } = payload
        return await this.$api.get(ENDPOINT_RFP_SPP_SECURITY_CODE, { vendorCandidateId })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    }, 
  }
}