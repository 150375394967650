/* eslint-disable */
import { ENDPOINT_USER_APPROVER_PROCUREMENT, ENDPOINT_RFQ_APPROVAL_LOG, 
  ENDPOINT_RFQ_APPROVAL_CHANGE_APPROVER, ENDPOINT_MASTERDATA_COA, 
  ENDPOINT_MASTERDATA_COST_CENTER, ENDPOINT_MASTERDATA_WBS_LEV3_CODE, 
  ENDPOINT_RFQ_APPROVAL_DETAIL, ENDPOINT_RFQ_APPROVAL_DELETE_DRAFT, 
  ENDPOINT_RFQ_APPROVAL_PROCUREMENT, ENDPOINT_RFQ_APPROVAL_APPROVAL,
  ENDPOINT_RFQ_APPROVAL_APPROVE_WITH_AGREEMENT, ENDPOINT_RFQ_APPROVAL_SECURITY_CODE,
  ENDPOINT_USER_REQUESTER_PROCUREMENT, ENDPOINT_RFQ_APPROVAL_CHANGE_REQUESTER,
  ENDPOINT_RFQ_APPROVAL_PREVIEW
} from '@/core/constant/menuEndpoint'
import { CHECKER, RECOMMENDATION, APPROVER  } from '@/core/constant/procurementApproval.js'
import { parseLocaleNumber, formatThousandSeparate } from "@/core/components/helpers/formatRupiah.js";
import { deepCloneAdvanced } from "@/core/components/helpers";
const mandatory = 'Wajib diisi'
const initState = {
  recommendations: { approvalType: RECOMMENDATION,approvalStatus: null, name: '', search: '', id: null, isDeleted: false, tempId: 'id0' },
  checker: { approvalType: CHECKER, approvalStatus: null, name: '', search: '', id: null, isDeleted: false, tempId: 'id0' },
  approver: { approvalType: APPROVER, approvalStatus: null, name: '', search: '', id: null, isDeleted: false, tempId: 'id0' },
}
const initStateErrorMaxLength = {
  'Note Internal BCA': '',
  'Exchange Rate': '',
  'Introduction / Background & Needs': '',
  'Project Goals': ''
}
const initDataCostBudgetInformationList = [{
  id: null,
  costCenterId: '',
  costCenterSearch: '',
  wbsElement: '',
  wbsName: '',
  wbsElementSearch: '',
  coaCode: '',
  coaDescription: '',
  coaCodeSearch: '',
  budgetValueIdr: '',
  isDeleted: false,
  error: {
    "Cost Center": '',
    "Budget Value": '',
  }
}]
  export const Store = {
  namespaced: true,
  name: 'procurementApprovalRfq',
  state: {
    counterInterval: null,
    dataProcurementApproval: {},
    costBudgetInformationListClone: [],
    logApproval: {},
    negotiationResult: {},
    editRequester: false,
    submittedProcurementApproval: false,
    isCreateProcurementApproval: false,
    errorMaxLength: initStateErrorMaxLength,
    errorRecommendation: {
      'Recommendation': false,
      'Approver': false
    },
    selectedRecommendation: [initState.recommendations],
    selectedChecker: [initState.checker],
    selectedApprover: [initState.approver],
    errorVendorCandidate: [],
    mainPage: '',
    waiverBg: '',
    errorSelectOne: false
  },
  mutations: {
    SET_MAIN_PAGE(state, payload) {
      state.mainPage = payload
    },
    SET_EDIT_REQUESTER(state, payload) {
      state.editRequester = payload
    },
    SET_CREATE_APPROVAL(state, payload) {
      state.isCreateProcurementApproval = payload
    },
    SET_SHOW_DETAIL_APPROVAL(state, payload) {
      state.submittedProcurementApproval = payload
    },
    SET_ERROR_MAX_LENGTH(state, payload) {
      state.errorMaxLength = payload
    },
    SET_ERROR_RECOMMENDATION(state, payload) {
      state.errorRecommendation = payload
    },
    SET_SELECTED_CHECKER(state, payload) {
      state.selectedChecker = payload
    },
    SET_SELECTED_RECOMMENDATION(state, payload) {
      state.selectedRecommendation = payload
    },
    SET_SELECTED_APPROVER(state, payload) {
      state.selectedApprover = payload
    },
    SET_ERROR_VENDOR_CANDIDATE(state, payload) {
      state.errorVendorCandidate = payload
    },
    SET_DATA_PROCUREMENT_APPROVAL(state, payload) {
      state.dataProcurementApproval = payload
    },
    SET_NEGOTIATION_RESULT(state, payload) {
      state.negotiationResult = payload
    },
    SET_COST_BUDGET_INFORMATION_LIST_CLONE(state, payload) {
      state.costBudgetInformationListClone = payload
    },
    SET_LOG_APPROVAL(state, payload) {
      state.logApproval = payload
    },
    SET_COUNTER_INTERVAL(state, payload) {
      state.counterInterval = payload
    },
    SET_WAIVERBG(state, payload) {
      state.waiverBg = payload
    },
    SET_ERR_SELECT_ONE(state, payload) {
      state.errorSelectOne = payload
    }
  },
  actions: {
    resetStateProcurement({ commit, state, dispatch }, payload) {
      commit('SET_SELECTED_CHECKER', [initState.checker])
      commit('SET_SELECTED_RECOMMENDATION', [initState.recommendations])
      commit('SET_SELECTED_APPROVER', [initState.approver])
      commit('SET_ERROR_RECOMMENDATION', {Recommendation: false, Approver: false})
      commit('SET_LOG_APPROVAL', {})
      commit('SET_ERROR_MAX_LENGTH', initStateErrorMaxLength)
      commit('SET_MAIN_PAGE', '')
    },
    async getApprovalLog({ commit, state, dispatch }, payload) {
      try {
        const { approvalId } = payload
        const response = await this.$api.get(ENDPOINT_RFQ_APPROVAL_LOG, { approvalId })
        commit('SET_LOG_APPROVAL', response.data.result)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async cancelProcurementApproval({ commit, state, dispatch }, payload) {
      try {
        const { approvalId } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_APPROVAL_DELETE_DRAFT}/${approvalId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getApproval({ commit, state, dispatch }, payload) {
      try {
        const { rfqId } = payload
        const response = await this.$api.get(ENDPOINT_RFQ_APPROVAL_DETAIL, {rfqId})
        const data = response.data.result.negotiationResult
        const negotiationResult = { ...data, 
          collapse: true, 
          errorMaxLength: {
            'Note Internal BCA': '',
            'Exchange Rate': ''
          },
          negotiationResultDetails: { ...data.negotiationResultDetails, exchangeRate: formatThousandSeparate(data.negotiationResultDetails.exchangeRate )}
        }
        const negotiationResultClone = deepCloneAdvanced(negotiationResult)
        const costBudgetInformationList = response.data.result.costBudgetInformationList?.length? response.data.result.costBudgetInformationList.map(e => ({ ...e, 
            budgetValueIdr: formatThousandSeparate(e.budgetValueIdr?.toString(), 'idr'), 
            costCenterSearch: e.costCenterId,
            wbsElementSearch: e.wbsElement,
            coaCodeSearch: e.coaCode,
            error: {
            "Cost Center": '',
            "Budget Value": '',
          } })) : initDataCostBudgetInformationList
        const costBudgetInformationListClone = deepCloneAdvanced(costBudgetInformationList)
        if (response.data.result.approvalList?.length) {
          const recommendations = response.data.result.approvalList?.filter(e => e.approvalType === RECOMMENDATION)?.map(e => {
            const { username, ...newRes } = { ...e, search: e.name, userName: e.username}
            return newRes})
          commit('SET_SELECTED_RECOMMENDATION', recommendations.length === 0 ? [initState.recommendations] : recommendations)
          const checker = response.data.result.approvalList?.filter(e => e.approvalType === CHECKER)?.map(e => {
            const { username, ...newRes } = { ...e, search: e.name, userName: e.username }
              return newRes})
          commit('SET_SELECTED_CHECKER', checker.length === 0 && recommendations.length === 0 ? [initState.checker] : checker)
          const approver = response.data.result.approvalList?.filter(e => e.approvalType === APPROVER)?.map(e => {
            const { username, ...newRes } = { ...e, search: e.name, userName: e.username }
            return newRes})
          commit('SET_SELECTED_APPROVER', approver.length === 0 ? [initState.approver] : approver)
        } else {
          commit('SET_SELECTED_CHECKER', [initState.checker])
          commit('SET_SELECTED_RECOMMENDATION', [initState.recommendations])
          commit('SET_SELECTED_APPROVER', [initState.approver])
        }
        commit('SET_DATA_PROCUREMENT_APPROVAL', { ...response.data.result, attachmentList: response.data.result.attachmentList || [], negotiationResult,
          costBudgetInformationList,
         })
        commit('SET_NEGOTIATION_RESULT', negotiationResultClone)
        commit('SET_COST_BUDGET_INFORMATION_LIST_CLONE', costBudgetInformationListClone)
        dispatch('getApprovalProcurementPrincipal', { rfqId, isMaximize: false })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getApprovalProcurementPrincipal({ commit, state, dispatch }, payload) {
      try {
        const { rfqId, isMaximize } = payload
        const dataState = isMaximize? state.negotiationResult : state.dataProcurementApproval.negotiationResult
        const exchangeRate = parseLocaleNumber(dataState.negotiationResultDetails.exchangeRate, dataState.negotiationResultDetails.currency)
        const { errorMaxLength, collapse, ...data } = {
          ...dataState,
          rfqId,
          negotiationResultDetails: { ...dataState.negotiationResultDetails, exchangeRate: +exchangeRate }
        }
        const response = await this.$api.post(ENDPOINT_RFQ_APPROVAL_PROCUREMENT, data)
        const result = response.data.result
        const stateNegotiationResultList = isMaximize? state.negotiationResult : state.dataProcurementApproval.negotiationResult
        const dataCommit = {
          ...stateNegotiationResultList,
          negotiationResultDetails: {
            ...stateNegotiationResultList.negotiationResultDetails,
            vatAmount: result.procurementApprovalDetails[0].vatAmount,
            finalPrice: result.procurementApprovalDetails[0].finalPrice,
            initialPrice: result.procurementApprovalDetails[0].initialPrice,
            grandTotalIdr: result.procurementApprovalDetails[0].grandTotalIdr,
            subTotal: result.procurementApprovalDetails[0].subTotal,
            resultItemList: stateNegotiationResultList.negotiationResultDetails.resultItemList.map((el, j) => ({ ...el, finalPrice: result.procurementApprovalDetails[0].itemList[j].finalPrice })),
          },
        }
        commit('SET_WAIVERBG', result.procurementApprovalDetails[0].waiverBg)
        if (isMaximize) {
          commit('SET_NEGOTIATION_RESULT', dataCommit)
        } else {
          commit('SET_DATA_PROCUREMENT_APPROVAL', { ...state.dataProcurementApproval, negotiationResult: dataCommit })
        }
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async dataApproval({ commit, state, dispatch }, payload) {
      const isSubmit = payload?.isSubmit
      //approvalList
      const selectedChecker = state.selectedChecker.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
      const selectedRecommendation = state.selectedRecommendation.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
      const selectedApprover = state.selectedApprover.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
      //data
      const { errorMaxLength, collapse, negotiationResultDetails, ...newRes } = state.dataProcurementApproval.negotiationResult
      const exchangeRate = parseLocaleNumber(negotiationResultDetails.exchangeRate, negotiationResultDetails.currency)
      const negotiationResult = { ...newRes,
        negotiationResultDetails: negotiationResultDetails === null? negotiationResultDetails : { ...negotiationResultDetails, exchangeRate: +exchangeRate}
      }
      const { isSubmitted, dataCommit, ...data} = {
        ...state.dataProcurementApproval,
        isSubmit,
        attachmentList: state.dataProcurementApproval.attachmentList,
        negotiationResult,
        costBudgetInformationList: state.dataProcurementApproval.costBudgetInformationList?.filter(e => (e.id || e.costCenterId || e.wbsElement || e.coaCode || e.budgetValueIdr))?.map(e => ({
          ...e,
          budgetValueIdr: parseLocaleNumber(e.budgetValueIdr, 'idr'),
        })),
        approvalList: [ ...selectedChecker, ...selectedRecommendation, ...selectedApprover ]
      }
      return data
    },
    async postApprovalPreview({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataApproval')
        const response = await this.$api.post(ENDPOINT_RFQ_APPROVAL_PREVIEW, data, { responseType: 'blob' })
        console.log(response, response.headers, 'response post approval preview');
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async postApproval({ commit, state, dispatch }, payload) {
      try {
        const data = await dispatch('dataApproval', payload)
        return await this.$api.post(ENDPOINT_RFQ_APPROVAL_DETAIL, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async putApprovalApprove({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, notes, action } = payload
        return await this.$api.put(`${ENDPOINT_RFQ_APPROVAL_APPROVAL}/${approvalId}`, { notes, action })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async putApprovalApproveWithAgreement({ commit, state, dispatch }, payload) {
      try {
        const data = payload
        return await this.$api.put(ENDPOINT_RFQ_APPROVAL_APPROVE_WITH_AGREEMENT, data)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async putChangeApprovers({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, reason } = payload
        const selectedChecker = state.selectedChecker.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
        const selectedRecommendation = state.selectedRecommendation.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
        const selectedApprover = state.selectedApprover.filter(e => e.userName).map((e,i) => ({ id: e.id, username: e.userName, approvalType: e.approvalType,  approvalTypeSeq: i+1, isDeleted: e.isDeleted }))
        const approvalList = [...selectedChecker, ...selectedRecommendation, ...selectedApprover]
        return await this.$api.put(ENDPOINT_RFQ_APPROVAL_CHANGE_APPROVER, { approvalId, reason, approvalList })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getApprovalSecurityCode({ commit, state, dispatch }, payload) {
      try {
        const { approvalId } = payload
        return await this.$api.get(`${ENDPOINT_RFQ_APPROVAL_SECURITY_CODE}/${approvalId}`)
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getMasterdataCostCenter({ commit, state, dispatch }, payload) {
      try {
        const { costCenterId } = payload
        return await this.$api.get(ENDPOINT_MASTERDATA_COST_CENTER, { costCenterId })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getMasterdataWbsLev3Code({ commit, state, dispatch }, payload) {
      try {
        const { wbsElement } = payload
        return await this.$api.get(ENDPOINT_MASTERDATA_WBS_LEV3_CODE, { wbsElement })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getMasterdataCoa({ commit, state, dispatch }, payload) {
      try {
        const { coaCode } = payload
        return await this.$api.get(ENDPOINT_MASTERDATA_COA, { coaCode })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getUserApproverProcurement({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, approvalType, nominalProject, nameFilter, usernameList } = payload
        return await this.$api.get(ENDPOINT_USER_APPROVER_PROCUREMENT, { approvalId, approvalType, nominalProject, nameFilter, usernameList })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async changeApprovals({commit, state}, isDone) {
      try {
        console.log(isDone, 'payload')
        if(isDone) {
          commit('SET_CREATE_APPROVAL', true)
          commit('SET_SHOW_DETAIL_APPROVAL', false)
          commit('SET_EDIT_REQUESTER', true)
        } else {
          commit('SET_SHOW_DETAIL_APPROVAL', true)
          commit('SET_EDIT_REQUESTER', false)
        }
      } catch (error) {
        return error.response
      }
    },
    checkErrorLength({ commit, state }, payload) {
      return Object.values(state.dataProcurementApproval.negotiationResult.errorMaxLength).some(e => e)
    },
    messageErrorLength({ commit, state }, payload) {
      return Object.keys(state.dataProcurementApproval.negotiationResult.errorMaxLength).filter(e => state.dataProcurementApproval.negotiationResult.errorMaxLength[e])
    },
    checkErrorRecommendation({ commit, state }, payload) {
      const {
        selectedRecommendation,
        selectedApprover
      } = state
      const recommendations = selectedRecommendation.filter(e => !e.isDeleted).filter(item => item.name !== '')
      const approver = selectedApprover.filter(e => !e.isDeleted).filter(item => item.name !== '')
      const filledApprover = approver.length > 0
      const filledRecommendations = recommendations.length > 0
      commit('SET_ERROR_RECOMMENDATION', {Recommendation: !filledRecommendations, Approver: !filledApprover})
      return filledRecommendations && filledApprover
    },
    messageErrorRecommendation({ commit, state }, payload) {
      const {errorRecommendation} = state
      return Object.keys(errorRecommendation).filter(e => errorRecommendation[e])
    },
    async checkErrorQtyOrder({ commit, state, dispatch }, payload) {
      return (state.dataProcurementApproval.negotiationResult.negotiationResultDetails.resultItemList.some(el => !!el.errorQtyOrder))
    },
    async getApprovalUserRequesterProcurement({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, project, nameFilter } = payload
        return await this.$api.get(ENDPOINT_USER_REQUESTER_PROCUREMENT, { approvalId, project, nameFilter })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async putApprovalChangeRequester({ commit, state, dispatch }, payload) {
      try {
        const { approvalId, newRequester, reason } = payload
        return await this.$api.put(ENDPOINT_RFQ_APPROVAL_CHANGE_REQUESTER, { approvalId, newRequester, reason })
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    commitErrorCostBudgetInformationSubmit({ commit, state }, payload) {
      const { dataProcurementApproval } = state
      const dataCommit = dataProcurementApproval.costBudgetInformationList.map((e, i) => {
        const condition = e.costCenterId || e.budgetValueIdr || i === 0
        return ({ ...e, 
          error: { ...e.error, 
            "Cost Center": condition && !e.costCenterId && !e.isDeleted ? mandatory : '',
            "Budget Value": condition && !e.budgetValueIdr && !e.isDeleted ? mandatory : e.error["Budget Value"] } 
          }
        )
      })
      commit('SET_DATA_PROCUREMENT_APPROVAL', { ...dataProcurementApproval, costBudgetInformationList: dataCommit })
      return dataCommit
    },
    async checkErrorCostBudgetInformation({ commit, state, dispatch }, payload) {
      const { isSubmit } = payload
      if (state.dataProcurementApproval.costBudgetInformationList?.length === 0) {
        const dataCommit = initDataCostBudgetInformationList
        commit('SET_DATA_PROCUREMENT_APPROVAL', { ...state.dataProcurementApproval, costBudgetInformationList: dataCommit })
      }
      let result = []
      if (isSubmit) {
        result = await dispatch('commitErrorCostBudgetInformationSubmit')
      } else {
        result = state.dataProcurementApproval.costBudgetInformationList.filter(obj => {
          return obj.error['Budget Value'] !== mandatory
        })
      }
      return result.every(e => Object.values(e.error).every(el => !el))
    }
  }

}