/* eslint-disable */
import { ENDPOINT_RFQ_QUOTATION_ITEM_LIST, ENDPOINT_RFQ_QUOTATION_ITEM_LIST_PAGINATION, ENDPOINT_RFQ_DETAILS_APPROVE, ENDPOINT_RFQ_DETAILS_REJECT,ENDPOINT_RFQ_DETAILS_SEND_RFQ, ENDPOINT_RFQ_DETAILS, 
  ENDPOINT_RFQ_DETAILS_CANCEL, ENDPOINT_RFQ_DETAILS_UPDATE_APPROVER } from '@/core/constant/menuEndpoint'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'


export const Store = {
  namespaced: true,
  name: 'quotationDetail',
  state: {
    activePage: 'quotation-detail',
    paramQuotationId: 0,
    quotationInformation: {
      isNewProject: true,
      projectId: "",
      projectName: "", 
      vendorId: "", 
      vendorName: "", 
      isNewVendor: false, 
      tahun: "", 
      budgetSpent: "", 
      kodeWbsLev1: "", 
      judulRfq: "", 
      notes: "",
      directApproverId: "", 
      directApproverName: ""
    },
    rfqItemLists: [],
    rfqItemListsPagination: {}
  },
  gettters: {

  },
  mutations: {
    SET_ACTIVE_PAGE(state, payload) {
      state.activePage = payload
    },
    SET_PARAM_QUOTATION_ID(state, payload) {
      state.paramQuotationId = payload
    },
    SET_QUOTATION_INFORMATION(state, payload) {
      state.quotationInformation = payload
    },
    SET_RFQ_ITEM_LISTS(state, payload) {
      state.rfqItemLists = payload
    },
    SET_RFQ_ITEM_LISTS_PAGINATION(state, payload) {
      state.rfqItemListsPagination = payload
    },
    SET_RFQ_REASON_CANCEL(state, payload) {
      state.reasonCancel = payload
    }
  },
  actions: {
    //Get API
    async getQuotationInformation({ commit, state, dispatch }, payload) {
      try {
        const rfqId = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_DETAILS}/${rfqId}`)
        console.log(response, 'response get quotation information');
        const result = response.data.result
        const dataCommit = { ...result, budgetSpent: formatThousandSeparate(result.budgetSpent?.toString())}
        commit('SET_QUOTATION_INFORMATION', dataCommit)
        return response
      } catch (error) {
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getQuotationItemList({ commit, state, dispatch }, payload) {
      try {
        const rfqId = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_QUOTATION_ITEM_LIST}/${rfqId}`)
        console.log(response, 'response get quotation item list');
        commit('SET_RFQ_ITEM_LISTS', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getQuotationItemListPagination({ commit, state, dispatch }, payload) {
      try {
        const { rfqId, page, size } = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_QUOTATION_ITEM_LIST_PAGINATION}/${rfqId}`, { page, size })
        console.log(response, 'response get quotation item list');
        commit('SET_RFQ_ITEM_LISTS_PAGINATION', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    //Api Put Approval Quotation
    async putRejectQuotation({ commit, state, rootState }, payload) {
      try {
        const { rfqId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_RFQ_DETAILS_REJECT}/${rfqId}`, { reason })
        console.log(response, 'response put reject quotation');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putApproveQuotation({ commit, state }, payload) {
      try {
        const { rfqId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_RFQ_DETAILS_APPROVE}/${rfqId}`, { reason })
        console.log(response, 'response put approve quotation');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putCancelQuotation({ commit, state, rootState}, payload) {
      try {
        const { rfqId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_RFQ_DETAILS_CANCEL}/${rfqId}`, { reason })
        console.log(response, 'response put cancel quotation');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //put change approver
    async putChangeApprover({ commit, state }, payload) {
      try {
        const data = payload
        const response = await this.$api.put(ENDPOINT_RFQ_DETAILS_UPDATE_APPROVER, data)
        console.log(response);
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    async putSendRFQ({ commit, state}, payload) {
      try {
        const response = await this.$api.put(ENDPOINT_RFQ_DETAILS_SEND_RFQ, payload)
        console.log(response, 'response put send rfq');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    }

  }
  
}