//Constant Status Project If needed

export const GSIT_01_APPROVED                                 = 'GSIT_01_APPROVED'
export const GSIT_01_AWAITING_APPROVAL                        = 'GSIT_01_AWAITING_APPROVAL'
export const GSIT_01_REVIEWER_AWAITING_APPROVAL               = 'GSIT_01_REVIEWER_AWAITING_APPROVAL'
export const UKKP_01_REVIEWER_AWAITING_APPROVAL               = 'UKKP_01_REVIEWER_AWAITING_APPROVAL'
export const SPV_ITP_01_AWAITING_APPROVAL                     = 'SPV_ITP_01_AWAITING_APPROVAL'
export const SPV_ITP_01_OPT_AWAITING_APPROVAL                 = 'SPV_ITP_01_OPT_AWAITING_APPROVAL'
export const GSIT_01_AWAITING_REVISION                        = 'GSIT_01_AWAITING_REVISION'
export const GSIT_01_DRAFT                                    = 'GSIT_01_DRAFT'
export const GSIT_01_REJECTED                                 = 'GSIT_01_REJECTED'
export const GSIT_02_APPROVED                                 = 'GSIT_02_APPROVED'
export const GSIT_02_AWAITING_DOCUMENT_REVIEW                 = 'GSIT_02_AWAITING_DOCUMENT_REVIEW'
export const GSIT_02_REJECTED                                 = 'GSIT_02_REJECTED'
export const GSIT_03_AWAITING_SCORING_ESCROW                  = 'GSIT_03_AWAITING_SCORING_ESCROW'
export const GSIT_03_AWAITING_SCORING_TEKNIS                  = 'GSIT_03_AWAITING_SCORING_TEKNIS'
export const GSIT_03_AWAITING_SCORING_TEKNIS_ESCROW           = 'GSIT_03_AWAITING_SCORING_TEKNIS_ESCROW'
export const GSIT_03_AWAITING_SCORING_REVISION                = 'GSIT_03_AWAITING_SCORING_REVISION'
export const UKKP_03_AWAITING_SCORING_REVISION                = 'UKKP_03_AWAITING_SCORING_REVISION'
export const GSIT_03_AWAITING_SCORING_APPROVAL                = 'GSIT_03_AWAITING_SCORING_APPROVAL'
export const UKKP_03_AWAITING_SCORING_APPROVAL                = 'UKKP_03_AWAITING_SCORING_APPROVAL'
export const ITP_03_AWAITING_SCORING_APPROVAL                 = 'ITP_03_AWAITING_SCORING_APPROVAL'
export const ITP_04_AWAITING_REVIEW                           = 'ITP_04_AWAITING_REVIEW'
export const SPV_ITP_01_APPROVED                              = 'SPV_ITP_01_APPROVED'
export const SPV_ITP_01_REJECTED                              = 'SPV_ITP_01_REJECTED'
export const STAFF_ITP_01_APPROVED                            = 'STAFF_ITP_01_APPROVED'
export const STAFF_ITP_01_AWAITING_APPROVAL                   = 'STAFF_ITP_01_AWAITING_APPROVAL'
export const STAFF_ITP_01_REJECTED                            = 'STAFF_ITP_01_REJECTED'
export const STAFF_ITP_02_APPROVED                            = 'STAFF_ITP_02_APPROVED'
export const STAFF_ITP_02_AWAITING_PRE_QUALIFICATION_PROCESS  = 'STAFF_ITP_02_AWAITING_PRE_QUALIFICATION_PROCESS'
export const STAFF_ITP_02_AWAITING_QUOTATION                  = 'STAFF_ITP_02_AWAITING_QUOTATION'
export const STAFF_ITP_02_REJECTED                            = 'STAFF_ITP_02_REJECTED'
export const STAFF_ITP_03_APPROVED                            = 'STAFF_ITP_03_APPROVED'
export const STAFF_ITP_03_AWAITING_DOCUMENT_REVIEW            = 'STAFF_ITP_03_AWAITING_DOCUMENT_REVIEW'
export const STAFF_ITP_03_REJECTED                            = 'STAFF_ITP_03_REJECTED'
export const UKKP_01_APPROVED                                 = 'UKKP_01_APPROVED'
export const UKKP_01_AWAITING_APPROVAL                        = 'UKKP_01_AWAITING_APPROVAL'
export const UKKP_01_AWAITING_REVISION                        = 'UKKP_01_AWAITING_REVISION'
export const UKKP_01_DRAFT                                    = 'UKKP_01_DRAFT'
export const UKKP_01_REJECTED                                 = 'UKKP_01_REJECTED'
export const UKKP_02_APPROVED                                 = 'UKKP_02_APPROVED'
export const UKKP_02_AWAITING_DOCUMENT_REVIEW                 = 'UKKP_02_AWAITING_DOCUMENT_REVIEW'
export const UKKP_02_REJECTED                                 = 'UKKP_02_REJECTED'
export const UKKP_03_AWAITING_SCORING_ESCROW                  = 'UKKP_03_AWAITING_SCORING_ESCROW'
export const UKKP_03_AWAITING_SCORING_TEKNIS                  = 'UKKP_03_AWAITING_SCORING_TEKNIS'
export const UKKP_03_AWAITING_SCORING_TEKNIS_ESCROW           = 'UKKP_03_AWAITING_SCORING_TEKNIS_ESCROW'
export const USER_01_APPROVED                                 = 'USER_01_APPROVED'
export const USER_01_AWAITING_APPROVAL                        = 'USER_01_AWAITING_APPROVAL'
export const USER_01_AWAITING_REVISION                        = 'USER_01_AWAITING_REVISION'
export const USER_01_DRAFT                                    = 'USER_01_DRAFT'
export const USER_01_REJECTED                                 = 'USER_01_REJECTED'
export const USER_02_AWAITING_DOCUMENT_REVIEW                 = 'USER_02_AWAITING_DOCUMENT_REVIEW'
export const VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT    = 'VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT'
export const VENDOR_02_AWAITING_PROPOSAL_PRICE_DOCUMENT       = 'VENDOR_02_AWAITING_PROPOSAL_PRICE_DOCUMENT'
export const COMPLETED                                        = 'COMPLETED'
export const SUBMITTED_GSIT                                   = 'SUBMITTED_GSIT'
export const SUBMITTED_UKKP                                   = 'SUBMITTED_UKKP'
export const SUBMITTED_REVISION_GSIT                          = 'SUBMITTED_REVISION_GSIT'
export const SUBMITTED_REVISION_UKKP                          = 'SUBMITTED_REVISION_UKKP'
export const APPROVED_GSIT                                    = 'APPROVED_GSIT'
export const APPROVED_UKKP                                    = 'APPROVED_UKKP'
export const REJECTED_GSIT                                    = 'REJECTED_GSIT'
export const REJECTED_UKKP                                    = 'REJECTED_UKKP'
export const SUBMITTED_APPROVAL_GSIT                          = 'SUBMITTED_APPROVAL_GSIT'
export const SUBMITTED_APPROVAL_UKKP                          = 'SUBMITTED_APPROVAL_UKKP'
export const CANCELED                                         = 'CANCELED'
export const GSIT_03_AWAITING_SCORING                         = 'GSIT_03_AWAITING_SCORING'
export const UKKP_03_AWAITING_SCORING                         = 'UKKP_03_AWAITING_SCORING'
export const RE_NEGOTIATION                                   = 'RE_NEGOTIATION'
export const UKKP_07_AWAITING_MEMO_CREATION                   = 'UKKP_07_AWAITING_MEMO_CREATION'
export const GSIT_07_AWAITING_MEMO_CREATION                   = 'GSIT_07_AWAITING_MEMO_CREATION'
export const USER_04_AWAITING_MEMO_CREATION                   = 'USER_04_AWAITING_MEMO_CREATION'
export const USER_03_APPROVAL_COMPLETED                       = 'USER_03_APPROVAL_COMPLETED'
export const NEGOTIATION_PROCESS_EDIT                         = 'NEGOTIATION_PROCESS_EDIT'
export const BAN_FPTN_APPROVAL                                = 'BAN_FPTN_APPROVAL'
export const BAN_FPTN_APPROVAL_EDIT                           = 'BAN_FPTN_APPROVAL_EDIT'
export const BAN_SCORING_APPROVAL                             = 'BAN_SCORING_APPROVAL'
export const BAN_SCORING_APPROVAL_EDIT                        = 'BAN_SCORING_APPROVAL_EDIT'
export const SKT_LOI_AWAITING_FOR_CREATION                    = 'SKT_LOI_AWAITING_FOR_CREATION'
export const SKT_LOI_AWAITING_FOR_REVISION                    = 'SKT_LOI_AWAITING_FOR_REVISION'
export const SKT_LOI_AWAITING_FOR_APPROVAL_REVIEWER_ITP       = 'SKT_LOI_AWAITING_FOR_APPROVAL_REVIEWER_ITP'
export const SKT_LOI_AWAITING_FOR_APPROVAL_APPROVER_ITP       = 'SKT_LOI_AWAITING_FOR_APPROVAL_APPROVER_ITP'
export const SKT_LOI_AWAITING_FOR_APPROVAL_REVIEWER_GSIT      = 'SKT_LOI_AWAITING_FOR_APPROVAL_REVIEWER_GSIT'
export const SKT_LOI_AWAITING_FOR_APPROVAL_APPROVER_GSIT      = 'SKT_LOI_AWAITING_FOR_APPROVAL_APPROVER_GSIT'
export const SKT_LOI_AWAITING_FOR_APPROVAL_REVIEWER_UKKP      = 'SKT_LOI_AWAITING_FOR_APPROVAL_REVIEWER_UKKP'
export const SKT_LOI_AWAITING_FOR_APPROVAL_APPROVER_UKKP      = 'SKT_LOI_AWAITING_FOR_APPROVAL_APPROVER_UKKP'
export const LOI_AWAITING_FOR_CREATION                        = 'LOI_AWAITING_FOR_CREATION'
export const LOI_AWAITING_FOR_REVISION                        = 'LOI_AWAITING_FOR_REVISION'
export const LOI_AWAITING_FOR_APPROVAL_REVIEWER_ITP           = 'LOI_AWAITING_FOR_APPROVAL_REVIEWER_ITP'
export const LOI_AWAITING_FOR_APPROVAL_APPROVER_ITP           = 'LOI_AWAITING_FOR_APPROVAL_APPROVER_ITP'
export const LOI_AWAITING_FOR_APPROVAL_REVIEWER_GSIT          = 'LOI_AWAITING_FOR_APPROVAL_REVIEWER_GSIT'
export const LOI_AWAITING_FOR_APPROVAL_APPROVER_GSIT          = 'LOI_AWAITING_FOR_APPROVAL_APPROVER_GSIT'
export const PROCUREMENT_PROCESS                              = 'PROCUREMENT_PROCESS'
export const SKT_LOI_SUBMITTED                                = 'SKT_LOI_SUBMITTED'
export const LOI_SUBMITTED                                    = 'LOI_SUBMITTED'
export const SPP_PROCESS                                      = 'SPP_PROCESS'