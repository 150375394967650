<template>
  <div v-if="approvalPage && isRejected && mainPage !== EDIT && menuRfpApprovalDetail?.canCreate && !counterInterval && requester">
    <div class="w-full p-4">
      <div class="">
        <ButtonGista 
          color="secondary"
          @click="clickEditApproval"
          customClass="w-full">
          Edit</ButtonGista>
      </div>
    </div>
  </div>
</template>

<script>
import { EDIT } from '@/core/constant/procurementApproval.js'

export default {
  name: "EditProcurementApproval",
  data() {
    return {
      EDIT
    }
  },
  computed: {
    logApproval() {
      return this.$store.state.procurementApproval.logApproval
    },
    isRejected() {
      return this.dataProcurementApproval.isRejected
    },
    dataProcurementApproval() {
      return this.$store.state.procurementApproval.dataProcurementApproval
    },
    mainPage() {
      return this.$store.state.procurementApproval.mainPage
    },
    menuRfpApprovalDetail() {
			return this.$store.getters['dashboard/menuRfpApprovalDetail']
		},
    approvalPage() {
      return this.$route.path.includes('approval')
    },
    userDetail() {
      return this.$store.state.dashboard.userProfile;
    },
    requester() {
      return this.logApproval.userRequester?.toLowerCase() === this.userDetail.username?.toLowerCase()
    },
    counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},

  },
  methods: {
    clickEditApproval() {
      this.$store.commit('procurementApproval/SET_MAIN_PAGE', EDIT)
    }
  }
}

</script>