<template>
  <div v-if="showDetailBAN && menuRfqNegotiationBanApprove && roleApprove">
    <div class="w-full p-6">
      <p class="mb-1 text-sm text-gray">BAN Verification</p>
      <p class="mb-6 text-sm text-gray-dark">Please verify the BAN of this vendor’s deal</p>
      
      <div class="flex justify-between">
        <ButtonGista 
          type="secondary"
          color="error"
          :isDark="false"
          @click="toggle"
          customClass="mr-6 w-1/2">
          Reject
        </ButtonGista>
        <ButtonGista 
          type="primary"
          color="success"
          @click="toggle2"
          class="w-1/2">
          Approve</ButtonGista>
      </div>
		</div>
    <GistaModal 
      :value="isOpen"
      customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="flex justify-center w-full">
            <h5 class="text-lg font-semibold text-gray">Tolak BAN</h5>
          </div>
          <div class="flex-wrap w-full mb-4">
            <ValidationProvider name="Reason" rules="required|max:500" v-slot="{errors}">
              <TextareaGista
                label="Reason"
                placeholder="Leave a comment here"
                v-model="reason"
                :error="!!errors[0]"
                mandatory
              >
                <template #message>
                  <p>{{errors[0]}}</p>
                </template>
              </TextareaGista>
            </ValidationProvider>

          </div>
          <div class="flex justify-center">
            <ButtonGista 
              type="primary"
              color="error"
              @click="handleSubmit(clickReject)"
              customClass="w-full sm:w-56">
              Reject
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
    <GistaModal 
      :value="isOpen2"
      customClass="max-w-2xl bg-white"
			@input="toggle2">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="w-full p-5">
          <div class="w-full mb-1">
            <h4 class="mb-8 text-3xl text-center font-medium">Setujui</h4>
            <div class="flex-wrap w-full mb-4">
              <ValidationProvider name="Reason" rules="max:500" v-slot="{errors}">
                <TextareaGista
                  label="Reason"
                  placeholder="Leave a comment here"
                  v-model="reason"
                  :error="!!errors[0]"
                >
                  <template #message>
                    <p>{{errors[0]}}</p>
                  </template>
                </TextareaGista>
              </ValidationProvider>

            </div>
          </div>
          <div class="flex justify-center">
            <ButtonGista 
              color="success"
              customClass="w-full sm:w-56"
              @click="handleSubmit(clickApprove)"
              >
              Approve
            </ButtonGista>

          </div>
        </div>
      </ValidationObserver>
		</GistaModal>
  </div>
</template>

<script>

export default {
  name: "RfqBanApproval",
  props: ["refetch", "rejected"],
  data() {
    return {
      isOpen: false,
      isOpen2: false,
      reason: ''
    }
  },
  computed: {
    banDetails() {
      return this.$store.state.negotiationRfq.banDetails
    },
    userProfile() {
      return this.$store.state.dashboard.userProfile
    },
    
    showDetailBAN() {
			return this.$store.state.negotiationRfq.showDetailBan
		},
    menuRfqNegotiationBanApprove() {
			return this.$store.getters['dashboard/menuRfqNegotiationBanApprove']?.canUpdate
		},
    detailBanApprover() {
      return this.$store.state.negotiationRfq.detailRFQNegoApprover
    },
    roleApprove() {
      const username = this.userProfile.username?.toLowerCase()
      return username === this.detailBanApprover.waitingApproval?.toLowerCase()
    },
    projectLogRfq() {
			return this.$store.state.projectLog.projectLogRfq
		},
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
      this.reason = ''
    },
    toggle2() {
      this.isOpen2 = !this.isOpen2
      this.reason = ''
    },
    fetchBanApproval() {
      this.$store.dispatch('negotiationRfq/refetchDetailRFQNegoApprover', { negotiationId: this.banDetails.rfqNegotiationId })
    },
    async clickApprove() {
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('negotiationRfq/putBanApprove', { banId: this.banDetails.rfqBanId, reason: this.reason })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalSuccessApproved = { value: true, title: response.data.result }
          this.modal.modalLoading = false
          this.toggle2()
          this.$store.commit('projectLog/SET_RFQ_LOG_ID', this.projectLogRfq?.rfqLogs?.at(-1)?.rfqLogId)
          this.$emit("refetch")
          this.fetchBanApproval()
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    },
    async clickReject() {
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('negotiationRfq/putBanReject', { banId: this.banDetails.rfqBanId, reason: this.reason })
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalSuccessApproved = { value: true, title: response.data.result }
          this.modal.modalLoading = false
          this.toggle()
          this.$store.commit('projectLog/SET_RFQ_LOG_ID', this.projectLogRfq?.rfqLogs?.at(-1)?.rfqLogId)
          this.$emit("refetch")
          this.$emit("rejected")
          this.fetchBanApproval()
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    }
  }
}
</script>