<template>
	<div class="w-full">
		<div class="w-full p-4 break-words">
			<div class="flex justify-between w-full">
				<div :class="`${rejectStatus? 'w-1/2':'w-full'}`" v-if="rejectStatus">
					<ButtonGista 
						type="secondary"
						color="success"
						@click="editQuotation"
						customClass="w-full">
						Edit Quotation</ButtonGista>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { PATH_ROOT } from '@/core/constant/routeName'
import { USER_01_AWAITING_REVISION, SPP_PROCESS } from '@/core/constant/statusProject'

export default {
	name: "CancelEditRfq",
	computed: {
		projectLogRfq() {
			return this.$store.state.projectLog.projectLogRfq
		},
		rejectStatus() {
			const status = [ USER_01_AWAITING_REVISION ]
			const rfqLogs = this.projectLogRfq?.rfqLogs
			const logsLength = rfqLogs.length
			if (logsLength > 0) {
				if(rfqLogs[logsLength - 1]?.statusProject === SPP_PROCESS) return status.includes(rfqLogs[logsLength - 2]?.statusProject)
				return status.includes(rfqLogs[logsLength - 1]?.statusProject)
			}
			return false
		},
	},
	methods: {
		editQuotation() {
			setTimeout(() => {
				this.$router.push(`/${PATH_ROOT}/edit-rfq/${this.$route.params.quotationId}`).catch(() => ({}))
			}, 200)
		},
	}
}
</script>