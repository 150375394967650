/* eslint-disable */
import { ENDPOINT_RFP_DETAILS_VENDOR_CANDIDATE, ENDPOINT_USER_APPROVER, ENDPOINT_USER_APPROVER_V2, ENDPOINT_RFP_GET_MGR, ENDPOINT_RFP_WBS, ENDPOINT_RFP_VENDOR,
  ENDOPINT_RFP_SCORING_TEKNIS_CRITERIA, ENDPOINT_RFP_PROPOSAL_INFORMATION, ENDOPINT_PROJECT_INFORMATION, ENDPOINT_PROJECT_REQUIREMENT, ENDPOINT_PROJECT_SCORING_INFORMATION } from '@/core/constant/menuEndpoint'
import { MESSAGE_TOTAL_SCORE } from '@/core/constant/errorMessage.js'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'


export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'rfp',

	state: {
    approverUkkp: [],
    approverList: [],
    rfpMgr: {
      userName: '',
      userId: '',
    },
    WBS: [],
    collapse: {
      proposalInformation: true,
      projectInformation: true,
      projectRequirement: true,
      vendorCandidate: true,
      scoringTeknis: true,
    },
    proposalInformation: {
      rfpId: 0,
      projectId: null,
      projectCode: null,
      noRfp: null,
      adaUkkp: false,
      isVisibleScoring: false,
      unitKerja: '',
      directApproverId: '',
      directApproverName: '',
      approverUkkpId: '',
      approverUkkpName: '',
      reviewerGsitId: '',
      reviewerGsitName: '',
      reviewerUkkpId: '',
      reviewerUkkpName: '',
      firstApproverDlogId: '',
      firstApproverDlogName: '',
      secondApproverDlogId: '',
      secondApproverDlogName: '',
      projectName: '',
      technicalCoordinatorId: '',
      technicalCoordinatorName: '',
      technicalCoordinatorEmail: '',
      technicalCoordinatorMobilePhone: '',
      technicalCoordinatorOfficePhone: '',
      ext: '',
      budgetSpent: '',
      kodeWbsLev1: '',
      isSoftware: true,
      notes: '',
      statusProject: "DRAFT",
      isDeleted: false,
    },
    projectInformation: {
      projectInformationId: 0,
      background: '',
      tujuanProyek: '',
      rfpId: 0,
      jangkaWaktuProyek: ''
    },
    projectRequirement: {
      persyaratanProdukDanJasa: "",
      persyaratanTeknis: "",
      arsitekturDanStandar: "",
      implementasi: "",
      pengembanganDanInterface: "",
      projectStatusReporting: "",
      sumberDayaYangTersedia: "",
      serviceLevel: "",
      pengamanTi: "",
      dokumentasi: "",
      pelatihan: "",
      dukunganPemeliharaan: "",
      acceptanceTest: "",
      referensi: "",
      rfpAttachments: [],
      rfpId: 0,
      
    },
    vendorList: [],
    vendorCandidate: [
      {
        rfpVendorCandidateId: null,
        vendorId: 0,
        vendorName: '',
        vendorEmail: '',
        isNewVendor: true,
        reason: '',
        isActive: true,
        isApprove: true,
        isPerluPoc: true,
        isDeleted: false,
        disqualifiedReason: '',
        disabled: false,
        isLoading: false,
        error: {
          vendorName: { value: false, message: '' },
          vendorNameDuplicate: { value: false, message: '' },
          vendorEmail: { value: false, message: '' },
          reason: { value: false, message: '' }
        },
        localPartners: []
      },
      {
        rfpVendorCandidateId: null,
        vendorId: 0,
        vendorName: '',
        vendorEmail: '',
        isNewVendor: true,
        reason: '',
        isActive: true,
        isApprove: true,
        isPerluPoc: true,
        isDeleted: false,
        disqualifiedReason: '',
        disabled: false,
        isLoading: false,
        error: {
          vendorName: { value: false, message: '' },
          vendorNameDuplicate: { value: false, message: '' },
          vendorEmail: { value: false, message: '' },
          reason: { value: false, message: '' }
        },
        localPartners: []
      },
      {
        rfpVendorCandidateId: null,
        vendorId: 0,
        vendorName: '',
        vendorEmail: '',
        isNewVendor: true,
        reason: '',
        isActive: true,
        isApprove: true,
        isPerluPoc: true,
        isDeleted: false,
        disqualifiedReason: '',
        disabled: false,
        isLoading: false,
        error: {
          vendorName: { value: false, message: '' },
          vendorNameDuplicate: { value: false, message: '' },
          vendorEmail: { value: false, message: '' },
          reason: { value: false, message: '' }
        },
        localPartners: []
      },
      {
        rfpVendorCandidateId: null,
        vendorId: 0,
        vendorName: '',
        vendorEmail: '',
        isNewVendor: true,
        reason: '',
        isActive: true,
        isApprove: true,
        isPerluPoc: true,
        isDeleted: false,
        disqualifiedReason: '',
        disabled: false,
        isLoading: false,
        error: {
          vendorName: { value: false, message: '' },
          vendorNameDuplicate: { value: false, message: '' },
          vendorEmail: { value: false, message: '' },
          reason: { value: false, message: '' }
        },
        localPartners: []
      },
    ],
    scoringTeknisCriteria: [
      {
        scoringTeknisCriteriaId: 1,
        name: "Vendor Data",
        sequence: 1,
        bobot: '',
        scoreTotal: 0,
        errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
        rfpScoringTeknis: [
          {
            bobot: '',
            sequence: 1,
            rfpScoringTeknisId: 0,
            name: "",
            isDeleted: false,
            error: { value: false, message: '' },
            errorSubField: { value: false, message: '' },
            errorScore: { value: false, message: '' },
          },
        ]
      },
      {
        scoringTeknisCriteriaId: 2,
        name: "Solution",
        sequence: 2,
        bobot: '',
        scoreTotal: 0,
        errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
        rfpScoringTeknis: [
          {
            bobot: '',
            sequence: 2,
            rfpScoringTeknisId: 0,
            name: "",
            isDeleted: false,
            error: { value: false, message: '' },
            errorSubField: { value: false, message: '' },
            errorScore: { value: false, message: '' },
          },
        ]
      },
      {
        scoringTeknisCriteriaId: 3,
        name: "Support",
        sequence: 3,
        bobot: '',
        scoreTotal: 0,
        errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
        rfpScoringTeknis: [
          {
            bobot: '',
            sequence: 3,
            rfpScoringTeknisId: 0,
            name: "",
            isDeleted: false,
            error: { value: false, message: '' },
            errorSubField: { value: false, message: '' },
            errorScore: { value: false, message: '' },
          },
        ]
      },
      {
        scoringTeknisCriteriaId: 4,
        name: "Price",
        sequence: 4,
        bobot: '25',
        scoreTotal: 0,
        errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
        rfpScoringTeknis: [
          {
            bobot: '',
            sequence: 4,
            rfpScoringTeknisId: 0,
            name: "",
            isDeleted: false,
            error: { value: false, message: '' },
            errorSubField: { value: false, message: '' },
            errorScore: { value: false, message: '' },
          },
        ]
      },
    ],
    scoringTeknisCriteria2: [],
	},

	getters: {
    
  },

	mutations: {
    SET_APPROVER_UKKP(state, payload) {
      state.approverUkkp = payload
    },
    SET_APPROVER_LIST(state, payload) {
      state.approverList = payload
    },
    SET_RFP_MGR(state, payload) {
      state.rfpMgr = payload
    },
    SET_WBS(state, payload) {
      state.WBS = payload
    },
    SET_COLLAPSE(state, payload) {
      state.collapse = payload
    },
    SET_PROPOSAL_INFORMATION(state, payload) {
      state.proposalInformation = payload
    },
    SET_PROJECT_INFORMATION(state, payload) {
      state.projectInformation = payload
    },
    SET_PROJECT_REQUIREMENT(state, payload) {
      state.projectRequirement = payload
    },
    SET_VENDOR_LIST(state, payload) {
      state.vendorList = payload
    },
    SET_VENDOR_CANDIDATE(state, payload) {
      state.vendorCandidate = payload
    },
    SET_SCORING_TEKNIS_CRITERIA(state, payload) {
      state.scoringTeknisCriteria = payload
    },
    SET_SCORING_TEKNIS_CRITERIA2(state, payload) {
      state.scoringTeknisCriteria2 = payload
    },

	},

	actions: {
    //get
    //for list approver
    async getApproverUkkp({ commit, dispatch, rootState }, payload) {
      try {
        const { role, name, project, id, type, approvers } = payload
        await commit('SET_APPROVER_UKKP', [])
        const response = await this.$api.get(ENDPOINT_USER_APPROVER, { role, name, project, id, type, approvers })
        console.log(response.data, 'approver ukkp')
        await commit('SET_APPROVER_UKKP', response.data.result)
        return response
      } catch (error) {
        await commit('SET_APPROVER_UKKP', [])
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getApproverList({ commit, dispatch, rootState }, payload) {
      try {
        const { applicationRoles, name, eselon } = payload
        await commit('SET_APPROVER_LIST', [])
        const response = await this.$api.get(ENDPOINT_USER_APPROVER_V2, { applicationRoles, name, eselon })
        console.log(response.data, 'approver list')
        await commit('SET_APPROVER_LIST', response.data.result)
        return response
      } catch (error) {
        await commit('SET_APPROVER_LIST', [])
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    //for get direct approver mgr default
    async getRfpMgr({ commit,dispatch }, payload) {
      try {
        const response = await this.$api.get(ENDPOINT_RFP_GET_MGR)
        console.log(response, 'get rfp mgr');
        commit('SET_RFP_MGR', response.data)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //for list WBS
    async getWBS({ commit, dispatch }, payload) {
      try {
        const { element } = payload
        const response = await this.$api.get(ENDPOINT_RFP_WBS, { element })
        console.log(response);
        commit('SET_WBS', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    //for list vendor
    async getVendor({ commit, state, dispatch }, payload) {
      try {
        const { vendorName } = payload
        const response = await this.$api.get(ENDPOINT_RFP_VENDOR, { vendorName })
        console.log(response.data, 'vendor list')
        commit('SET_VENDOR_LIST', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        commit('SET_VENDOR_LIST', [])
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response

      }
		},
    //for get data initial scoring teknis, like bobot, name, 
    async getScoringTeknisCriteria({ commit }) {
      try {
        const response = await this.$api.get(ENDOPINT_RFP_SCORING_TEKNIS_CRITERIA)
        console.log(response, 'scoring teknis criteria');
        const scoringTeknisCriteria = response.data.result.map((e,i) => {
          return { ...e, bobot: `${i === 3? e.bobot : ''}`, scoreTotal: 0, errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE }, rfpScoringTeknis: Array(3).fill().map(item => (
            { bobot: '', name: "", scoringTeknisCriteriaId: e.scoringTeknisCriteriaId, rfpScoringTeknisId: 0, sequence: e.sequence, isDeleted: false, error: { value: false, message: '' }, errorSubField: { value: false, message: '' }, errorScore: { value: false, message: '' } }
          ))}
        })
        const scoringTeknisCriteriaDeepClone = JSON.parse(JSON.stringify(scoringTeknisCriteria))
        commit('SET_SCORING_TEKNIS_CRITERIA', scoringTeknisCriteria)
        commit('SET_SCORING_TEKNIS_CRITERIA2', scoringTeknisCriteriaDeepClone)
        return response
      } catch (error) {
        console.log(error);
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    /** end get create new project */

    /** start get continue draft rfp */
    async getProposalInformation({ commit, dispatch}, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_PROPOSAL_INFORMATION}/${rfpId}`)
        console.log(response, 'get proposal information rfpId');
        const result = response.data.result
        //format budgetSpent to currency 1,000,000,000
        const dataCommit = {
          ...result,
          ext: result.ext || '',
          budgetSpent: formatThousandSeparate(result.budgetSpent?.toString()),
        }
        commit('SET_PROPOSAL_INFORMATION', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    async getProjectInformation({ commit }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDOPINT_PROJECT_INFORMATION}/${rfpId}`)
        console.log(response, 'get project information project id');
        const result = response.data.result
        const dataCommit = {
          projectInformationId: result.projectInformationId || 0,
          background: result.background || '',
          tujuanProyek: result.tujuanProyek ||'',
          rfpId: result.rfpId ||0,
          jangkaWaktuProyek: result.jangkaWaktuProyek ||''
        }
        commit('SET_PROJECT_INFORMATION', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getProjectRequirement({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_PROJECT_REQUIREMENT}/${rfpId}`)
        console.log(response, 'get project requirement project id');
        if (!response.data.result) throw new Error('fail to get project requirement')
        const result = response.data.result
        const dataCommit = { ...result, rfpAttachments: result.rfpAttachments.map(item => {
          
          return {
            documentNo: item.documentNo,
            fileName: item.fileName,
            filePath: item.filePath,
            fileRef: item.fileRef,
            isDeleted: false,
            rfpAttachmentId: item.rfpAttachmentId
          }
        })}
        commit('SET_PROJECT_REQUIREMENT', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getProjectVendorCandidate({ commit, state, dispatch }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_DETAILS_VENDOR_CANDIDATE}/${rfpId}`)
        console.log(response, 'get vendor candidate project id');
        if (response.data.result) {
          const result = response.data.result
          const dataCommit = result.map((e, i) => {
            return {
              rfpVendorCandidateId: e.rfpVendorCandidateId,
              vendorId: e.vendorId,
              vendorName: e.vendorName,
              vendorEmail: e.vendorEmail,
              reason: e.reason,
              isActive: e.isActive,
              isApprove: e.isApprove,
              isPerluPoc: e.isPerluPoc,
              disqualifiedReason: e.disqualifiedReason,
              isDeleted: e.isDeleted,
              isNewVendor: e.isNewVendor,
              disabled: false,
              isLoading: false,
              error: {
                vendorName: { value: false, message: '' },
                vendorNameDuplicate: { value: false, message: '' },
                vendorEmail: { value: false, message: '' },
                reason: { value: false, message: '' }
              },
              localPartners: e.localPartners?.map(el => ({ ...el, isLoading: false, error: {
                vendorName: { value: false, message: '' },
                vendorNameDuplicate: { value: false, message: '' },
                vendorEmail: { value: false, message: '' },
                reason: { value: false, message: '' }
              }, }))
            }
          })
          console.log(dataCommit, 'dataCommit');
          commit('SET_VENDOR_CANDIDATE', dataCommit)
        }
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getProjectScoringInformation({ commit, state }, payload) {
      try {
        const rfpId = payload
        const response = await this.$api.get(`${ENDPOINT_PROJECT_SCORING_INFORMATION}/${rfpId}`)
        console.log(response, 'response get project scoring');
        const dataResponse = response.data.result
        if(dataResponse.length === 0) {
          const scoringTeknisCriteria = [
            {
              scoringTeknisCriteriaId: 1,
              name: "Vendor Data",
              sequence: 1,
              bobot: '',
              scoreTotal: 0,
              errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
              rfpScoringTeknis: [
                {
                  bobot: '',
                  sequence: 1,
                  rfpScoringTeknisId: 0,
                  name: "",
                  isDeleted: false,
                  error: { value: false, message: '' },
                  errorSubField: { value: false, message: '' },
                  errorScore: { value: false, message: '' }
                },
              ]
            },
            {
              scoringTeknisCriteriaId: 2,
              name: "Solution",
              sequence: 2,
              bobot: '',
              scoreTotal: 0,
              errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
              rfpScoringTeknis: [
                {
                  bobot: '',
                  sequence: 2,
                  rfpScoringTeknisId: 0,
                  name: "",
                  isDeleted: false,
                  error: { value: false, message: '' },
                  errorSubField: { value: false, message: '' },
                  errorScore: { value: false, message: '' }
                },
              ]
            },
            {
              scoringTeknisCriteriaId: 3,
              name: "Support",
              sequence: 3,
              bobot: '',
              scoreTotal: 0,
              errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
              rfpScoringTeknis: [
                {
                  bobot: '',
                  sequence: 3,
                  rfpScoringTeknisId: 0,
                  name: "",
                  isDeleted: false,
                  error: { value: false, message: '' },
                  errorSubField: { value: false, message: '' },
                  errorScore: { value: false, message: '' }
                },
              ]
            },
            {
              scoringTeknisCriteriaId: 4,
              name: "Price",
              sequence: 4,
              bobot: '25',
              scoreTotal: 0,
              errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
              rfpScoringTeknis: [
                {
                  bobot: '',
                  sequence: 4,
                  rfpScoringTeknisId: 0,
                  name: "",
                  isDeleted: false,
                  error: { value: false, message: '' },
                  errorSubField: { value: false, message: '' },
                  errorScore: { value: false, message: '' }
                },
              ]
            },
          ]
          await commit('SET_SCORING_TEKNIS_CRITERIA', scoringTeknisCriteria)
          return
        }
        const dataCommit = dataResponse.map((e,i) => {
          const rfpScoringTeknis = e.rfpScoringTeknis.length === 0 ? [{
              bobot: '',
              name: "",
              rfpScoringTeknisId: 0,
              sequence: e.sequence,
              scoringTeknisCriteriaId: e.scoringTeknisCriteriaId,
              error: { value: false, message: '' },
              errorSubField: { value: false, message: '' }, 
              errorScore: { value: false, message: '' }
            }]
            : e.rfpScoringTeknis.map((el,j) => {
              return {
                bobot: el.bobot,
                name: el.name,
                rfpScoringTeknisId: el.rfpScoringTeknisId,
                sequence: el.sequence,
                scoringTeknisCriteriaId: e.scoringTeknisCriteriaId,
                error: { value: false, message: '' },
                errorSubField: { value: false, message: '' }, 
                errorScore: { value: false, message: '' }
              }
            })
          const scoreTotal = rfpScoringTeknis?.reduce((a,b) => +a + +b.bobot, 0)
          return { ...e, scoreTotal: scoreTotal, errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE }, rfpScoringTeknis: rfpScoringTeknis }
        })
        const dataCommitDeepClone = JSON.parse(JSON.stringify(dataCommit))
        commit('SET_SCORING_TEKNIS_CRITERIA', dataCommit)
        commit('SET_SCORING_TEKNIS_CRITERIA2', dataCommitDeepClone)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    /** end get continue draft rfp */
    

    //post
    //put
    //used for add vendor candidate in project detail
    async putProjectVendorCandidate({ commit, state }, payload ) {
      try {
        console.log(state.vendorCandidate, 'state vendor candidate before put');
        
        const vendorCandidate = state.vendorCandidate.filter(e => e.vendorName).map(e => {
          return {
            rfpVendorCandidateId: e.rfpVendorCandidateId,
            vendorId: e.vendorId,
            isNewVendor: e.isNewVendor,
            vendorName: e.vendorName,
            vendorEmail: e.vendorEmail,
            reason: e.reason,
            isApprove: e.isApprove,
            isPerluPoc: e.isPerluPoc,
            isActive: e.isActive,
            disqualifiedReason: e.disqualifiedReason,
            isDeleted: e.isDeleted,
            localPartners: e.localPartners.filter(el => el.vendorName)
          }
        })
        const data = {
          rfpId: state.proposalInformation.rfpId,
          vendorCandidate: vendorCandidate
        }

        console.log(data, 'payload put vendor candidate');
        const response = await this.$api.put(ENDPOINT_RFP_DETAILS_VENDOR_CANDIDATE, data)
        console.log(response, 'response put vendor candidate')
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //for reset state store rfp to initial
    async resetState({ commit, state }, payload) {
      const proposalInformation = {
        rfpId: 0,
        projectId: null,
        projectCode: null,
        noRfp: null,
        adaUkkp: false,
        unitKerja: '',
        approverUkkpId: '',
        approverUkkpName: '',
        directApprover: '',
        projectName: '',
        technicalCoordinatorId: '',
        technicalCoordinatorName: '',
        technicalCoordinatorEmail: '',
        technicalCoordinatorMobilePhone: '',
        technicalCoordinatorOfficePhone: '',
        ext: '',
        budgetSpent: '',
        kodeWbsLev1: '',
        isSoftware: true,
        notes: '',
        statusProject: "DRAFT",
        isDeleted: false,
      }
      await commit('SET_PROPOSAL_INFORMATION', proposalInformation)
      const projectInformation = {
        projectInformationId: 0,
        background: '',
        tujuanProyek: '',
        rfpId: 0,
        jangkaWaktuProyek: ''
      }
      await commit('SET_PROJECT_INFORMATION', projectInformation)
      const projectRequirement = {
        persyaratanProdukDanJasa: "",
        persyaratanTeknis: "",
        arsitekturDanStandar: "",
        implementasi: "",
        pengembanganDanInterface: "",
        projectStatusReporting: "",
        sumberDayaYangTersedia: "",
        serviceLevel: "",
        pengamanTi: "",
        dokumentasi: "",
        pelatihan: "",
        dukunganPemeliharaan: "",
        acceptanceTest: "",
        referensi: "",
        rfpAttachments: [],
        rfpId: 0,
      }
      await commit('SET_PROJECT_REQUIREMENT', projectRequirement)

      const vendorCandidate = [
        {
          rfpVendorCandidateId: null,
          vendorId: 0,
          vendorName: '',
          vendorEmail: '',
          isNewVendor: true,
          reason: '',
          isActive: true,
          isApprove: true,
          isPerluPoc: true,
          isDeleted: false,
          disqualifiedReason: '',
          disabled: true,
          isLoading: false,
          error: {
            vendorName: { value: false, message: '' },
            vendorEmail: { value: false, message: '' },
            reason: { value: false, message: '' }
          },
          localPartners: []
        },
        {
          rfpVendorCandidateId: null,
          vendorId: 0,
          vendorName: '',
          vendorEmail: '',
          isNewVendor: true,
          reason: '',
          isActive: true,
          isApprove: true,
          isPerluPoc: true,
          isDeleted: false,
          disqualifiedReason: '',
          disabled: true,
          isLoading: false,
          error: {
            vendorName: { value: false, message: '' },
            vendorEmail: { value: false, message: '' },
            reason: { value: false, message: '' }
          },
          localPartners: []
        },
        {
          rfpVendorCandidateId: null,
          vendorId: 0,
          vendorName: '',
          vendorEmail: '',
          isNewVendor: true,
          reason: '',
          isActive: true,
          isApprove: true,
          isPerluPoc: true,
          isDeleted: false,
          disqualifiedReason: '',
          disabled: true,
          isLoading: false,
          error: {
            vendorName: { value: false, message: '' },
            vendorEmail: { value: false, message: '' },
            reason: { value: false, message: '' }
          },
          localPartners: []
        },
        {
          rfpVendorCandidateId: null,
          vendorId: 0,
          vendorName: '',
          vendorEmail: '',
          isNewVendor: true,
          reason: '',
          isActive: true,
          isApprove: true,
          isPerluPoc: true,
          isDeleted: false,
          disqualifiedReason: '',
          disabled: true,
          isLoading: false,
          error: {
            vendorName: { value: false, message: '' },
            vendorEmail: { value: false, message: '' },
            reason: { value: false, message: '' }
          },
          localPartners: []
        },
      ]
      await commit('SET_VENDOR_CANDIDATE', vendorCandidate)

      const scoringTeknisCriteria = [
        {
          scoringTeknisCriteriaId: 1,
          name: "Vendor Data",
          sequence: 1,
          bobot: '',
          scoreTotal: 0,
          errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
          rfpScoringTeknis: [
            {
              bobot: 10,
              sequence: 1,
              rfpScoringTeknisId: 0,
              name: "",
              isDeleted: false,
              error: { value: false, message: '' },
              errorSubField: { value: false, message: '' },
              errorScore: { value: false, message: '' }
            },
          ]
        },
        {
          scoringTeknisCriteriaId: 2,
          name: "Solution",
          sequence: 2,
          bobot: '',
          scoreTotal: 0,
          errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
          rfpScoringTeknis: [
            {
              bobot: '',
              sequence: 2,
              rfpScoringTeknisId: 0,
              name: "",
              isDeleted: false,
              error: { value: false, message: '' },
              errorSubField: { value: false, message: '' },
              errorScore: { value: false, message: '' }
            },
          ]
        },
        {
          scoringTeknisCriteriaId: 3,
          name: "Support",
          sequence: 3,
          bobot: '',
          scoreTotal: 0,
          errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
          rfpScoringTeknis: [
            {
              bobot: '',
              sequence: 3,
              rfpScoringTeknisId: 0,
              name: "",
              isDeleted: false,
              error: { value: false, message: '' },
              errorSubField: { value: false, message: '' },
              errorScore: { value: false, message: '' }
            },
          ]
        },
        {
          scoringTeknisCriteriaId: 4,
          name: "Price",
          sequence: 4,
          bobot: '25',
          scoreTotal: 0,
          errorScoreTotal: { value: false, message: MESSAGE_TOTAL_SCORE },
          rfpScoringTeknis: [
            {
              bobot: '',
              sequence: 4,
              rfpScoringTeknisId: 0,
              name: "",
              isDeleted: false,
              error: { value: false, message: '' },
              errorSubField: { value: false, message: '' },
              errorScore: { value: false, message: '' }
            },
          ]
        },
      ]
      await commit('SET_SCORING_TEKNIS_CRITERIA', scoringTeknisCriteria)
      const collapse = {
        proposalInformation: true,
        projectInformation: true,
        projectRequirement: true,
        vendorCandidate: true,
        scoringTeknis: true,
      }
      commit('SET_COLLAPSE', collapse)
    }
	}
}