<template>
  <div v-if="sktLoiPage && menuRfpSktLoiChangeApproval?.canUpdate && someWaitingApproval && !counterInterval && requester" class="w-full">
		<div class="w-full px-4 pb-4">
			<div class="">
				<ButtonGista type="secondary"
					color="primary"
					@click="toggleIsOpen"
					customClass="w-full">
					Change Approver</ButtonGista>
			</div>
		</div>
		<GistaModal :value="isOpen"
			customClass="max-w-2xl bg-white"
			@input="toggleIsOpen">
      <ValidationObserver v-slot="{ handleSubmit }">
				<div class="w-full p-5">
					<div class="flex justify-center w-full mb-4">
						<h5 class="text-lg font-semibold text-gray">Change Approver</h5>
					</div>
					<div class="w-full mb-4">
            <ValidationProvider name="newApprover"
              rules="required"
              v-slot="{ errors }">
              <AutocompleteNewSolutip
                label="New Approver"
                placeholder="Search"
                :options="approverList"
                @updateInput="inputSearchApprover($event)"
                @updateAutoComplete="changeApprover($event)"
                @focus="focusApprover()"
                @blur="blurApprover()"
                :error="!!errors[0]"
                v-model="form.search"
                :isLoading="isLoading"
                :mandatory="true"
                textField="name"
                textField2="jobTitle"
                iconLeft="icon-search text-xl text-gray-light"
              >
                <template #message>
                  <p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
                </template>
              </AutocompleteNewSolutip>
            </ValidationProvider>
					</div>
					<div class="w-full mb-4">
						<ValidationProvider name="notes"
							rules="required|max:500"
							v-slot="{ errors }">
							<TextareaGista label="Notes"
								placeholder="Leave a comment here"
								v-model="form.notes"
								:error="!!errors[0]"
								:mandatory="true">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>
					<div class="flex justify-end">
						<ButtonGista type="primary"
							color="success"
							@click="handleSubmit(submitChange)"
							customClass="w-full sm:w-56">
							Change Approver
						</ButtonGista>
					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>

<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import { MESSAGE_SUCCESS_CHANGE_APPROVAL } from '@/core/constant/successMessage'

export default {
  name: 'ApprovalChangeApprover',
  data() {
    return {
      isOpen: false,
      form: {
        username: '',
        name: '',
        notes: '',
        search: ''
      },
      fields: [],
      isLoading: false,
      applicationRoles: {
        REVIEWER_DLOG: 'SOLUTIP_SPV_LOG,SOLUTIP_SPV1_LOG,SOLUTIP_SPV2_LOG,SOLUTIP_SPV3_LOG',
        APPROVER_DLOG: 'SOLUTIP_SPV1_LOG,SOLUTIP_SPV2_LOG,SOLUTIP_SPV3_LOG',
        REVIEWER_GSIT: 'SOLUTIP_SPV1_GSIT,SOLUTIP_SPV2_GSIT,SOLUTIP_SPV3_GSIT',
        APPROVER_GSIT: 'SOLUTIP_SPV1_GSIT,SOLUTIP_SPV2_GSIT,SOLUTIP_SPV3_GSIT,SOLUTIP_EVP',
        REVIEWER_UKKP: 'SOLUTIP_SPV1_KP,SOLUTIP_SPV2_KP,SOLUTIP_SPV3_KP',
        APPROVER_UKKP: 'SOLUTIP_SPV1_KP,SOLUTIP_SPV2_KP,SOLUTIP_SPV3_KP',
			},
      eselon: 's1,s2,s3,s4',
      REVIEWER_DLOG: 'REVIEWER_DLOG'
    };
  },
  computed: {
    draftSktLoi: {
      get() {
        return this.$store.state.sktLoi.draftSktLoi
      },
      set(value) {
        this.$store.commit('sktLoi/SET_DRAFT_SKT_LOI', value)
      }
    },
    filterForApproverList() {
			const people = []
      for (const key in this.draftSktLoi.approvals) {
        if (this.draftSktLoi.approvals[key].username) {
          people.push(this.draftSktLoi.approvals[key].username)
        }
      }
      if (this.form.username) {
        people.push(this.form.username)
      }
      return people
		},
    approverList() {
			return this.$store.state.rfp.approverList.filter(e => !this.filterForApproverList.includes(e.userName))
		},
    onReviewApproval() {
      return this.draftSktLoi.approvals.find(e => e.approvalStatus === 'AWAITING_APPROVAL')
    },
    sktLoiPage() {
      return this.$route.path.includes('skt-loi')
    },
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
    userDetail() {
      return this.$store.state.dashboard.userProfile;
    },
    requester() {
      return this.draftSktLoi.requester?.toLowerCase() === this.userDetail.username?.toLowerCase()
    },
    counterInterval() {
			return this.$store.state.projectLog.counterInterval
		},
    menuRfpSktLoiChangeApproval() {
			return this.$store.getters['dashboard/menuRfpSktLoiChangeApproval']
		},
    someWaitingApproval() {
      return this.draftSktLoi.approvals?.some(e => e.approvalStatus === 'AWAITING_APPROVAL')
    }
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen
    },
    async getApproverList(key) {
      this.isLoading = true
      await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles[key], name: this.form.search, eselon: key === this.REVIEWER_DLOG? this.eselon : '' })
      this.isLoading = false
    },
    inputSearchApprover() {
      const type = this.onReviewApproval.approvalType
      this.getApproverList(type)
		},
    focusApprover() {
      const type = this.onReviewApproval.approvalType
      this.getApproverList(type)
		},
    async changeApprover(e) {
      this.form = {
        ...this.form,
        username: e.userName,
        name: e.name,
        search: e.name
      }
		},
    blurApprover() {
			if (!this.form.search) {
				this.form = {
          ...this.form,
          username: '',
          name: '',
          search: ''
        }
			} else {
        this.form = {
          ...this.form,
          search: this.form.name
        }
			}
		},
    async submitChange() {
      this.modal.modalLoading = true
      const response = await this.$store.dispatch('sktLoi/changeApproval', { rfpId: this.$route.params.projectId, data : this.form })
      if (response?.status < 300) {
        this.$store.dispatch('projectLog/refetchSktLoiLog', { rfpId: this.$route.params.projectId })
        this.modal.modalSuccessApproved = { value: true, title: MESSAGE_SUCCESS_CHANGE_APPROVAL }
        this.toggleIsOpen()
      }
      this.modal.modalLoading = false
    },
  },
  components: {
    AutocompleteNewSolutip
  }
};
</script>