/* eslint-disable */
import { ENDPOINT_RFP_NEGOTIATION_BAN_VERIFICATOR_CHANGE, ENDPOINT_RFP_NEGOTIATION, ENDPOINT_RFP_NEGOTIATION_UPLOAD_OFFER, 
  ENDPOINT_RFP_NEGOTIATION_BAN_APPROVE, ENDPOINT_RFP_NEGOTIATION_BAN_REJECT, ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE, ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_SUBMIT, ENDPOINT_RFP_SCORING_TEKNIS_PRICE_CRITERIA,
  ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_APPROVE, ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_REJECT, ENDPOINT_RFP_NEGOTIATION_SCHEDULE_CONFIRM, ENDPOINT_RFP_NEGOTIATION_OFFER_DELETE,
  ENDPOINT_RFP_NEGOTIATION_OFFER_SAVE, ENDPOINT_RFP_NEGOTIATION_BAN, ENDPOINT_RFP_NEGOTIATION_OFFER_REVISION, ENDPOINT_RFP_NEGOTIATION_DETAILS_OFFER_REVISION_SUBMIT, ENDPOINT_RFP_NEGOTIATION_BAN_SUBMIT, 
  ENDPOINT_RFP_NEGOTIATION_RENEGOTIATION, ENDPOINT_RFP_NEGOTIATION_EXPORT_XLSX, ENDPOINT_RFP_NEGOTIATION_EXPORT_MENU, ENDPOINT_RFP_NEGOTIATION_OFFER_LIST, ENDPOINT_RFP_NEGOTIATION_DETAILS_BAN,
  ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_LOG_APPROVAL, ENDPOINT_RFP_NEGOTIATION_SUMMARY_WITH, ENDPOINT_RFP_NEGOTIATION_OFFER_LAST, ENDPOINT_RFP_NEGOTIATION_OFFER_GET,
  ENDPOINT_RFP_NEGOTIATION_DETAILS, ENDPOINT_RFP_NEGOTIATION_BAN_REPORT, ENDPOINT_RFP_NEGOTIATION_BAN_PREVIEW, ENDPOINT_KARYAWAN_NEGOTIATOR, ENDPOINT_RFP_NEGOTIATION_DETAILS_BAN_APPROVER,
  ENDPOINT_MASTERDATA_TAX, ENDPOINT_USER_APPROVER_NEGOTIATION
} from '@/core/constant/menuEndpoint'
import { OPTION_SEND_EMAIL } from '@/core/constant/negotiation.js'
export const Store = {
  namespaced: true,
  name: 'negotiation',
  state: {
    showReviseOffer: false,
    showDealBan: false,
    showEditBan: false,
    showDetailBan: false,
    vendorDetail: null,
    showUploadOffer: false,
    showEditOffer: false,
    showDetailOffer: false,
    negotiationOfferTable: {
      items: [],
      finalTotal: null,
      ppn: null,
      ppnPrice: null,
      totalPrice: null,
    },
    loadingGetNegotiationList: false,
    negotiationList: [],
    negotiationDetails: {},
    masterTax: [],
    exportMenu: {},
    negotiationId: null,
    reviseOffer: {
      negotiationId: null,
      summaryWith: null,
      projectId: null,
      projectName: null,
      requestNo: null,
      vendorName: null,
      requestById: null,
      requestByName: null,
      picGsitId: null,
      picGsitName: null,
      picUkkpId: null,
      picUkkpName: null,
      offerNo: null,
      offerDate: null,
      projectType: null,
      meeting: null,
      timeStart: null,
      timeEnd: null,
      location: null,
      viaOnline: null,
      firstNegotiatorId: null,
      firstNegotiatorName: null,
      secondNegotiatorId: null,
      secondNegotiatorName: null,
      negotiationParticipant: null,
      termOfPayment: null,
      isWaiverBg: null,
      waiverBg: null,
      negotiationNotes: null,
      itpVerificatorId: null,
      itpVerificatorName: null,
      representGsitId: null,
      representGsitName: null,
      representUkkpId: null,
      representUkkpName: null,
      directApproverItpId: null,
      directApproverItpName: null,
      directApproverGsitId: null,
      directApproverGsitName: null,
      directApproverUkkpId: null,
      directApproverUkkpName: null,
      proofOfMeetingDocumentNo: null,
      proofOfMeetingFileName: null,
      proofOfMeetingFilePath: null,
      deletedProofOfNegotiationDocumentNoList: [],
      createdBy: null,
      negotiationDetailsRev: [],
      attachments: [],
    },
    negotiationOfferItems: [],
    negotiator: [],
    negotiationOfferLast: {},
    negotiationOfferGet: {},
    banDetails: {
      negotiationId: 0,
      summaryWith: null,
      projectId: "",
      projectName: "",
      requestNo: "",
      vendorName: "",
      requestById: "",
      requestByName: "",
      picGsitId: "",
      picGsitName: "",
      picUkkpId: "",
      picUkkpName: "",
      offerNo: "",
      offerDate: "",
      projectType: [],
      meeting: null,
      meetingDate: "",
      timeStart: "",
      timeEnd: "",
      location: "",
      viaOnline: "",
      firstNegotiatorId: "",
      firstNegotiatorName: "",
      secondNegotiatorId: "",
      secondNegotiatorName: "",
      negotiationParticipant: "",
      termOfPayment: "",
      waiverBg: "",
      negotiationNote: null,
      internalNote: null,
      itpVerificatorId: "",
      itpVerificatorName: "",
      representGsitId: "",
      representGsitName: "",
      representUkkpId: "",
      representUkkpName: "",
      directApproverItpId: "",
      directApproverItpName: "",
      directApproverGsitId: "",
      directApproverGsitName: "",
      directApproverUkkpId: "",
      directApproverUkkpName: "",
      proofOfMeetingDocumentNo: null,
      proofOfMeetingFileName: null,
      proofOfMeetingFilePath: null,
      fileQuotationDocumentNo: null,
      fileQuotationFileName: null,
      fileQuotationFilePath: null,
      createdBy: "",
      createdDate: "",
      negotiationStatus: "",
      attachments: []
    },
    summaryWith: {},
    banList: [],
    approverNegotiation: [],
    scoringVendorTeknisTree: [
      {
        scoringTeknisCriteriaId: 1, name: 'Vendor Data', sequence: 1, bobot: 25, 
        points: [
          {
            bobot: 25,
            name: "value-1",
            rfpScoringTeknisId: 350,
            scores: [
              {
                rfpScoringVendorTeknisId: null, rfpVendorCandidateId: 206, skalaNilai: null
              },
            ],
            sequence: 1
          }
        ]
      },
      {
        scoringTeknisCriteriaId: 2, name: 'Solution', sequence: 2, bobot: 25, 
        points: [
          {
            bobot: 20,
            name: "value-1",
            rfpScoringTeknisId: 351,
            scores: [
              {
                rfpScoringVendorTeknisId: null, rfpVendorCandidateId: 206, skalaNilai: null
              },
            ],
            sequence: 2
          }
        ]
      },
      {
        scoringTeknisCriteriaId: 3, name: 'Support', sequence: 3, bobot: 20, 
        points: [
          {
            bobot: 30,
            name: "value-1",
            rfpScoringTeknisId: 352,
            scores: [
              {
                rfpScoringVendorTeknisId: null, rfpVendorCandidateId: 206, skalaNilai: null
              },
            ],
            sequence: 3
          }
        ]
      },
      {
        scoringTeknisCriteriaId: 4, name: 'Price', sequence: 4, bobot: 30, 
        points: [
          {
            bobot: 25,
            name: "value-1",
            rfpScoringTeknisId: 353,
            scores: [
              {
                rfpScoringVendorTeknisId: null, rfpVendorCandidateId: 206, skalaNilai: null
              },
            ],
            sequence: 4
          }
        ]
      },

    ],
    detailBanApprover: {},
    detailBanApproverInitial: {},
    scoringPriceLogApproval: {},
    scoringPriceLogApprovalInitial: {},
    showVendorDetails: false,
    counterInterval: null,
  },
  gettters: {

  },
  mutations: {
    SET_SHOW_REVISE_OFFER(state, payload) {
      state.showReviseOffer = payload
    },
    SET_SHOW_DEAL_BAN(state, payload) {
      state.showDealBan = payload
    },
    SET_SHOW_EDIT_BAN(state, payload) {
      state.showEditBan = payload
    },
    SET_SHOW_DETAIL_BAN(state, payload) {
      state.showDetailBan = payload
    },
    SET_VENDOR_DETAIL(state, payload) {
      state.vendorDetail = payload
    },
    SET_SHOW_UPLOAD_OFFER(state, payload) {
      state.showUploadOffer = payload
    },
    SET_SHOW_EDIT_OFFER(state, payload) {
      state.showEditOffer = payload
    },
    SET_SHOW_DETAIL_OFFER(state, payload) {
      state.showDetailOffer = payload
    },
    SET_NEGOTIATION_OFFER_TABLE(state, payload) {
      state.negotiationOfferTable = payload
    },
    SET_LOADING_GET_NEGOTIATION_LIST(state, payload) {
      state.loadingGetNegotiationList = payload
    },
    SET_NEGOTIATION_LIST(state, payload) {
      state.negotiationList = payload
    },
    SET_NEGOTIATION_DETAILS(state, payload) {
      state.negotiationDetails = payload
    },
    SET_MASTER_TAX(state, payload) {
      state.masterTax = payload
    },
    SET_EXPORT_MENU(state, payload) {
      state.exportMenu = payload
    },
    SET_NEGOTIATION_ID(state, payload) {
      state.negotiationId = payload
    },
    SET_REVISE_OFFER(state, payload) {
      state.reviseOffer = payload
    },
    SET_NEGOTIATION_OFFER_ITEMS(state, payload) {
      state.negotiationOfferItems = payload
    },
    SET_NEGOTIATOR(state, payload) {
      state.negotiator = payload
    },
    SET_NEGOTITATION_OFFER_LAST(state, payload) {
      state.negotiationOfferLast = payload
    },
    SET_NEGOTIATION_OFFER_GET(state, payload) {
      state.negotiationOfferGet = payload
    },
    SET_BAN_DETAILS(state, payload) {
      state.banDetails = payload
    },
    SET_BAN_LIST(state, payload) {
      state.banList = payload
    },
    SET_APPROVER_NEGOTIATION(state, payload) {
      state.approverNegotiation = payload
    },
    SET_SUMMARY_WITH(state, payload) {
      state.summaryWith = payload
    },
    SET_SCORING_VENDOR_TEKNIS_TREE(state, payload) {
      state.scoringVendorTeknisTree = payload
    },
    SET_DETAIL_BAN_APPROVER(state, payload) {
      state.detailBanApprover = payload
    },
    SET_DETAIL_BAN_APPROVER_INITIAL(state, payload) {
      state.detailBanApproverInitial = payload
    },
    SET_SCORING_PRICE_LOG_APPROVAL(state, payload) {
      state.scoringPriceLogApproval = payload
    },
    SET_SCORING_PRICE_LOG_APPROVAL_INITIAL(state, payload) {
      state.scoringPriceLogApprovalInitial = payload
    },
    SET_SHOW_VENDOR_DETAILS(state, payload) {
      state.showVendorDetails = payload
    },
    SET_COUNTER_INTERVAL(state, payload) {
      state.counterInterval = payload
    }
  },
  actions: {
    //get
    async getNegotiation({ commit, state }, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION}/${rfpId}`)
        console.log(response, 'response negotiation list');
        const result = response.data.result
        commit('SET_NEGOTIATION_LIST', result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }

    },
    async getNegotiationDetails({ commit, state }, payload) {
      try {
        const { vendorCandidateId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_DETAILS}/${vendorCandidateId}`)
        console.log(response, 'response negotiation details');
        commit('SET_NEGOTIATION_DETAILS', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getNegotiationMasterTax({ commit, state }, payload) {
      try {
        const response = await this.$api.get(ENDPOINT_MASTERDATA_TAX)
        console.log(response, 'get master tax');
        commit('SET_MASTER_TAX', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getNegotiationOffer({ commit, state,}, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_OFFER_GET}/${negotiationId}`)
        console.log(response, 'response get negotiation offer');
        commit('SET_NEGOTIATION_OFFER_GET', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getNegotiationOfferLast({ commit, state,}, payload) {
      try { 
        const { vendorCandidateId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_OFFER_LAST}/${vendorCandidateId}`)
        console.log(response, 'response get negotiation offer last');
        commit('SET_NEGOTITATION_OFFER_LAST', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getNegotiationExportMenu({ commit, state, }, payload) {
      try {
        const { vendorCandidateId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_EXPORT_MENU}/${vendorCandidateId}`)
        console.log(response, 'response get details export menu');
        commit('SET_EXPORT_MENU', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getNegotiator({ commit, state }, payload) {
      try {
        const { name, isNegotiationParticipant } = payload
        const response = await this.$api.get(ENDPOINT_KARYAWAN_NEGOTIATOR, { name, isNegotiationParticipant })
        console.log(response, 'response get negotiator');
        commit('SET_NEGOTIATOR', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getUserApproverNegotiation({ commit, state, }, payload) {
      try {
        const { name, project, param, negotiationId } = payload
        const response = await this.$api.get(ENDPOINT_USER_APPROVER_NEGOTIATION, { name: name, project, param, negotiationId })
        console.log(response, 'response get user approver negotiation');
        commit('SET_APPROVER_NEGOTIATION', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getNegotiationOfferRevision({ commit, state, }, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_OFFER_REVISION}/${negotiationId}`)
        console.log(response, 'response get negotiation offer revision');
        const result = response.data.result
        const dataCommit = { 
          ...result, 
          meeting: (result.meeting && result.meeting[0].toUpperCase() + result.meeting.slice(1)) || '',
          projectType:  result.projectType || [], 
          negotiationParticipant: result.negotiationParticipant || [],
          attachments: result.attachments || [],
          deletedProofOfNegotiationDocumentNoList: [],
          optionSendEmail: !state.negotiationDetails?.isLocalPartner ? OPTION_SEND_EMAIL.none : result.optionSendEmail
        }
        commit('SET_REVISE_OFFER', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getNegotiationOfferItems({ commit, state,}, payload) {
      try {
        const { vendorCandidateId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_OFFER_LIST}/${vendorCandidateId}`)
        console.log(response, 'reponse get negotiation Offer Items');
        commit('SET_NEGOTIATION_OFFER_ITEMS', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getBanDetails({ commit, state,}, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_DETAILS_BAN}/${negotiationId}`)
        console.log(response, 'response get ban details');
        const dataCommit = { ...response.data.result, waiverBg: response.data.result.waiverBg || '' }
        commit('SET_BAN_DETAILS', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getBanList({ commit, state,}, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_BAN}/${rfpId}`)
        console.log(response, 'response get band list');
        const result = response.data.result
        const dataCommit = result.map(e => ({ ...e, collapse: false }))
        commit('SET_BAN_LIST', dataCommit)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getSummaryWith({ commit, state,}, payload) {
      try {
        const { negotiationId, vendorCandidateId } = payload
        const response = await this.$api.get(ENDPOINT_RFP_NEGOTIATION_SUMMARY_WITH, { negotiationId, vendorCandidateId })
        console.log(response, 'response get summary with');
        commit('SET_SUMMARY_WITH', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getScoringPriceEditCriteria({ commit, state,}, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_SCORING_TEKNIS_PRICE_CRITERIA}/${rfpId}`)
        console.log(response, 'response get scoring price edit criteria');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getScoringPrice({ commit, state,}, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE}/${rfpId}`)
        console.log(response, 'response scoring price');
        commit('SET_SCORING_VENDOR_TEKNIS_TREE', response.data.result.criterias)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getScoringPriceLogApproval({ commit, state,}, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_LOG_APPROVAL}/${rfpId}`)
        console.log(response, 'response get scoring price log approval');
        commit('SET_SCORING_PRICE_LOG_APPROVAL', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getScoringPriceLogApprovalInitial({ commit, state,}, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_LOG_APPROVAL}/${rfpId}`)
        console.log(response, 'response get scoring price log approval initial');
        commit('SET_SCORING_PRICE_LOG_APPROVAL_INITIAL', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async refetchScoringPriceLogApproval({ commit, state, dispatch, rootState }, payload) {
      const { rfpId } = payload
      await dispatch('getScoringPriceLogApprovalInitial', { rfpId })
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
      commit('SET_COUNTER_INTERVAL', setInterval( async () => {
        await dispatch('getScoringPriceLogApproval', { rfpId })
      }, 3000))
      setTimeout(() => {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }, 30000)
    },
    async getDetailBanApprover({ commit, state, }, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_DETAILS_BAN_APPROVER}/${negotiationId}`)
        console.log(response, 'response get detail ban approver');
        commit('SET_DETAIL_BAN_APPROVER', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getDetailBanApproverInitial({ commit, state, }, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_DETAILS_BAN_APPROVER}/${negotiationId}`)
        console.log(response, 'response get detail ban approver initial');
        commit('SET_DETAIL_BAN_APPROVER_INITIAL', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async refetchDetailBanApprover({ commit, state, dispatch, rootState }, payload) {
      const { negotiationId } = payload
      await dispatch('getDetailBanApprover', { negotiationId })
      if (state.counterInterval) {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }
      commit('SET_COUNTER_INTERVAL', setInterval( async () => {
        await dispatch('getDetailBanApprover', { negotiationId })
      }, 3000))
      setTimeout(() => {
        clearInterval(state.counterInterval)
        commit('SET_COUNTER_INTERVAL', null)
      }, 30000)
    },
    async getBanReport({ commit, state, dispatch }, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.get(`${ENDPOINT_RFP_NEGOTIATION_BAN_REPORT}/${negotiationId}`, null, { responseType: 'blob' })
        console.log(response, response.headers, 'response get ban report');
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        console.log(error);
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
        return error.response
      }
    },
    //post
    async postSaveOffer({ commit, state }, payload) {
      try {
        const formData = payload
        const response = await this.$api.post(ENDPOINT_RFP_NEGOTIATION_OFFER_SAVE, formData)
        console.log(response, 'response post save offer');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async postBanSubmit({ commit, state, }, payload) {
      try {
        const response = await this.$api.post(ENDPOINT_RFP_NEGOTIATION_BAN_SUBMIT, payload)
        console.log(response, 'response post submit ban');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async postNegotiationOfferUpload({ commit, state,}, payload) {
      try {

        const response = await this.$api.post(ENDPOINT_RFP_NEGOTIATION_UPLOAD_OFFER, payload)
        console.log(response, 'response post negotiation offer upload');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async postNegotiationOfferRevisionSaveAsDraft({ commit, state,}, payload) {
      try {
        const response = await this.$api.post(ENDPOINT_RFP_NEGOTIATION_OFFER_REVISION, payload)
        console.log(response, 'response post negotiation offer revision');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async postNegotiationOfferRevisionSubmit({ commit, state,}, payload) {
      try {
        const response = await this.$api.post(ENDPOINT_RFP_NEGOTIATION_DETAILS_OFFER_REVISION_SUBMIT, payload)
        console.log(response, 'response post negotiation offer revision');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async postBanSaveAsDraft({ commit, state, }, payload) {
      try {
        const response = await this.$api.post(ENDPOINT_RFP_NEGOTIATION_BAN, payload)
        console.log(response, 'response post save as draft ban');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async postNegotiationDetailsExportExcel({ commit, state,}, payload) {
      try {
        const response = await this.$api.post(ENDPOINT_RFP_NEGOTIATION_EXPORT_XLSX, payload, { responseType: 'blob' })
        console.log(response, response.data, response.headers, 'response post negotiation details export excel');
        const blob = new Blob([response.data])
        const link = window.URL.createObjectURL(blob)
        const fileLink = document.createElement('a');
        fileLink.href = link;
        fileLink.setAttribute('download', response.headers['x-customheader-filename']);
        document.body.appendChild(fileLink);
        fileLink.click()
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async postBanPreview({ commit, state, dispatch }, payload) {
      try {
        const response = await this.$api.post(`${ENDPOINT_RFP_NEGOTIATION_BAN_PREVIEW}`, payload, { responseType: 'blob' })
        console.log(response, response.headers, 'response post ban preview');
        dispatch('file/fileReaderOpenNewTab', response, { root: true })
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async postOfferChangeCost({ commit, state }, payload) {
      try {
        const response = await this.$api.post(`rfp/negotiation/details/offer/onchange`, payload)
        console.log(response, 'response post offer change cost');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //put
    async putNegotationChangeVerificator({ commit, state,}, payload) {
      try {
        const data = payload
        const response = await this.$api.put(ENDPOINT_RFP_NEGOTIATION_BAN_VERIFICATOR_CHANGE, data)
        console.log(response, 'response put change verificator');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putNegotiationScheduleConfirm({ commit, state }, payload) {
      try {
        const { vendorCandidateId } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_NEGOTIATION_SCHEDULE_CONFIRM}/${vendorCandidateId}` )
        console.log(response, 'response put schedule confirm');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putBanApprove({ commit, state,}, payload) {
      try {
        const { candidateId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_NEGOTIATION_BAN_APPROVE}?candidateId=${candidateId}`, { reason })
        console.log(response, 'response put ban approve');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putBanReject({ commit, state,}, payload) {
      try {
        const { candidateId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_NEGOTIATION_BAN_REJECT}?candidateId=${candidateId}`, { reason })
        console.log(response, 'response put ban reject');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putScoringPrice({ commit, state, rootState }, payload) {
      try {
        const { rfpId } = payload
        const scoringVendorTeknisTree2 = rootState.projectDetail.scoringVendorTeknisTree2
        const attachments = rootState.projectDetail.attachments
        const scores = []
        const remarks = []
        state.scoringVendorTeknisTree.forEach((e,i) => {
          remarks.push({ scoringTeknisCriteriaId: scoringVendorTeknisTree2[i].scoringTeknisCriteriaId, remarks: scoringVendorTeknisTree2[i].remarks })
          e.points.forEach(el => {
            el.scores.forEach(ele => {
              scores.push({ ...ele, skalaNilai: +ele.skalaNilai, rfpScoringTeknisId: el.rfpScoringTeknisId })
            })
          })
        })
        const data = {
          rfpId: rfpId,
          scores: scores,
          remarks: remarks,
          attachments: attachments,
        }
        const response = await this.$api.put(ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE, data)
        console.log(response, 'response put scoring price');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putScoringPriceSubmit({ commit, state,}, payload) {
      try {
        const { rfpId } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_SUBMIT}/${rfpId}`)
        console.log(response, 'response put scoring price submit');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putScoringPriceApprove({ commit, state,}, payload) {
      try {
        const { rfpId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_APPROVE}/${rfpId}`, { reason })
        console.log(response, 'response put scoring price approve');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putScoringPriceReject({ commit, state,}, payload) {
      try {
        const { rfpId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_NEGOTIATION_SCORING_PRICE_REJECT}/${rfpId}`, { reason })
        console.log(response, 'response put scoring price reject');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putReNegotiation({ commit, state,}, payload) {
      try {
        const { vendorCandidateId } = payload
        const response = await this.$api.put(`${ENDPOINT_RFP_NEGOTIATION_RENEGOTIATION}?candidateId=${vendorCandidateId}`)
        console.log(response, 'response put re negotiation');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //delete
    async deleteNegotiationOffer({ commit, state,}, payload) {
      try {
        const { negotiationId } = payload
        const response = await this.$api.delete(`${ENDPOINT_RFP_NEGOTIATION_OFFER_DELETE}/${negotiationId}`)
        console.log(response, 'response delete negotiation offer');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    }
  }
  
}